import { BACKEND_URL, ETH_SCAN_BASE_URL, FAUCET_BASE_URL, RPC_URL } from "./configs";


function getGuthubAuthUrl() {
  return `${BACKEND_URL}/user/login/github`;
}
// function getGoogleAuthUrl() {
//   return `${BACKEND_URL}/user/login/google`;
// }
function githubAuth(code) {
  login(code, "github")
}

// async function googleAuth(code) {
//   login(code, "google")
// }

async function login(code, auth) {
  const url = `${BACKEND_URL}/user/login/${auth}/callback?code=${code}`;

  // console.log("Login URL", url);
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    if (response.status === 200) {
      // console.log("Success");
      try {
        const userResponse = await fetch(`${BACKEND_URL}/user`, {
          method: "GET",
          credentials: "include",
        });
        if (userResponse.status === 200) {
          return {
            data: {
              msg: "success",
              user: await userResponse.json()
            },
            err: null,
          };
        } else {
          return {
            data: null,
            err: {
              msg: "Sorry! Login failed. Please try again later.",
            },
          };
        }
      } catch (err) {
        return {
          data: null,
          err: {
            msg: "Sorry! Login failed. Please try again later.",
          },
        };
      }
    } else {
      return {
        data: null,
        err: {
          msg: "Sorry! Login failed. Please try again later.",
        },
      };
    }
  } catch (err) {
    return {
      data: null,
      err: {
        msg: "Sorry! Login failed. Please try again later.",
      },
    };
  }
}

async function getUser() {
  const url = `${BACKEND_URL}/user`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status !== 200) {
      const err = await response.json();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.json();
      // console.log(data)
      return {
        data,
        err: null,
        status: response.status,
      };
    }

  } catch (err) {
    console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function getAllUserContainers() {
  const url = `${BACKEND_URL}/user/container`;
  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.status !== 200) {
      const err = await response.json();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.json();
      // console.log(data)
      return {
        data,
        err: null,
        status: response.status,
      };
    }

  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function postControlDocker(ghToken, orgName, repoName, commitHash) {
  const url = `${BACKEND_URL}/app/create`;
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
      body: `{
        "ghToken": "${ghToken}",
          "orgName":  "${orgName}",
          "repoName": "${repoName}",
          "commitHash": "${commitHash}"
      }`
    })
    if (response.status === 200) {
      const data = await response.json()
      return {
        data,
        err: null,
        status: response.status,
      };

    } else {
    }
  } catch (_) {
    return {
      err: "error"
    }
  }
}

async function postSurvey(source, profile, usecase) {
  const url = `${BACKEND_URL}/user/survey`;
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
      body: `{
        "sourceOfAwareness": "${source}",
          "currentProfile":  "${profile}",
          "primaryUseCase": "${usecase}"
      }`
    })
    if (response.status === 200) {
      const data = await response.json()
      // console.log(">>"+data)
    } else {
    }
  } catch (_) {
    return {
      err: "error"
    }
  }
}

async function getContainerDetails(hash) {
  const url = `${BACKEND_URL}/user/container/settings/${hash}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = (await response.json())[0];
    return {
      data,
      err: null,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}
async function getGithubRepos() {
  const url = `${BACKEND_URL}/app/repos`
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status !== 200) {
      const err = await response.json();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.json();
      // console.log(data)
      return {
        data,
        err: null,
        status: response.status,
      };
    }

  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }

}

async function getGithubUser() {
  const url = `${BACKEND_URL}/app/`
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status !== 200) {
      const err = await response.json();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.json();
      // console.log(data)
      return {
        data,
        err: null,
        status: response.status,
      };
    }

  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function getTesting(commitHash) {
  const url = `${BACKEND_URL}/app/testing?commitHash=${commitHash}`
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      }

    })
    const data = await response.json();
    return {
      data,
      err: null,
      status: response.status,
    };

  } catch (e) {
    console.log(e)
  }
}

async function getDecoding(testRunId) {
  const url = `${BACKEND_URL}/app/testing/${testRunId}/decoded`
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      }

    })
    const data = await response.json();
    return {
      data,
      err: null,
      status: response.status,
    };

  } catch (e) {
    console.log(e)
  }
}




function getRpcUrl(nodeHash) {
  const url = `${RPC_URL}/${nodeHash}`;
  return url;
}

function getScanUrl(nodeHash) {
  const url = `${ETH_SCAN_BASE_URL}/${nodeHash}`;
  return url;
}

function getFaucetUrl(nodeHash) {
  const url = `${FAUCET_BASE_URL}/${nodeHash}`;
  return url;
}

async function restartContainer(nodeHash) {
  const url = `${BACKEND_URL}/user/container/node/restart/${nodeHash}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    return {
      status: response.status,
      data,
      err: null,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function deleteContainer(nodeHash) {
  const url = `${BACKEND_URL}/user/container/${nodeHash}`;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
    });
    const data = response;
    return {
      status: response.status,
      data,
      err: null,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function createContainer(config) {
  const url = `${BACKEND_URL}/user/container`;
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(config),
    });
    const data = response;
    return {
      data,
      err: null,
      status: response.status,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function updateContainer(config, nodeHash) {
  const url = `${BACKEND_URL}/user/container/${nodeHash}`;
  try {
    const response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(config),
    });
    const data = response;
    return {
      data,
      err: null,
      status: response.status,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

async function uploadArtifactArray(artifactArray, nodeHash) {
  const url = `${BACKEND_URL}/abis`;
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nodeId: nodeHash,
        abis: artifactArray,
      }),
    });
    if (response.status !== 200) {
      const err = await response.text();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.text();
      return {
        data,
        err: null,
        status: response.status,
      };
    }
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err: {
        msg: "Error connecting to server. Please try again later.",
      },
    };
  }
}

/****** ADMIN CONTROLS *****/
async function isAdmin() {
  const url = `${BACKEND_URL}/admin`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = response;
    return {
      data,
      err: null,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err: {
        msg: "Sorry! Login failed. Please try again later.",
      },
    };
  }
}

async function adminAllDetails() {
  const url = `${BACKEND_URL}/admin/detail`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    return { data }
  } catch (err) {
    // console.log(err)
  }
}

async function getAllContainers() {
  const url = `${BACKEND_URL}/admin/container`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    if (response.status !== 200) {
      const err = await response.json();
      return {
        data: null,
        err,
        status: response.status,
      };
    } else {
      const data = await response.json();
      data.status = response.status;
      return {
        data,
        err: null,
        status: response.status,
      };
    }
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err: {
        msg: "Error connecting to server. Please try again later.",
      },
    };
  }
}


async function adminDelete(hash) {
  const url = `${BACKEND_URL}/admin/container/${hash}`;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
    });
    const data = response;
    return {
      status: response.status,
      data,
      err: null,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }

}


/***************************/
export {
  login,
  getGuthubAuthUrl,
  getUser,
  // getGoogleAuthUrl,
  githubAuth,
  // googleAuth,
  getGithubUser,
  getGithubRepos,
  getAllUserContainers,
  getContainerDetails,
  getRpcUrl,
  getScanUrl,
  getFaucetUrl,
  restartContainer,
  deleteContainer,
  createContainer,
  updateContainer,
  uploadArtifactArray,
  postSurvey,
  postControlDocker,
  getTesting,
  getDecoding,
  // Admin
  isAdmin,
  getAllContainers,
  adminAllDetails,
  adminDelete,
};