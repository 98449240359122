
import React, { useState } from 'react'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from 'axios';
import { useEffect } from 'react';
import { getGithubRepos, getGithubUser, postControlDocker, getTesting, getDecoding } from '../api/bbapp';
import { CircularProgress } from "@mui/material";


function AppContainerList() {
  const darkButton = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
  });

  const [githubId, setGithubId] = useState("")
  const [githubToken, setGithubToken] = useState("")

  const [rep, setRep] = useState("");
  const [repos, setRepos] = useState("")
  const [isFound, setIsFound] = useState(false)
  const [isError, setIsError] = useState(false)

  const [gitUser, setGitUser] = useState("")

  const [isCheck, setIsCheck] = useState(false)

  const [commitHash, setCommitHash] = useState("")
  const [testData, setTestData] = useState()
  const [containerId, setContainerId] = useState()
  const [testRunId, setTestRunId] = useState()
  const [decoderData, setDecoderData] = useState()
  const [decoderTx, setDecoderTx] = useState()
  const [loading, setLoading] = useState(false)
  const [decoderLoading, setDecoderLoading] = useState(true)

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(async () => {
    const response = await getGithubRepos();
    console.log("Repos", response)
    const publicRepos = response?.data.public;
    const privateRepos = response?.data.private;
    const combinedRepos = new Set([...publicRepos, ...privateRepos]);
    setRepos(combinedRepos);
    console.log("SetRepo", repos)
  }, [])

  const reposArray = Array.from(repos);

  async function getUserDetails() {
    const response = await getGithubUser();
    const user = response.data;
    setGithubId(user?.oAuthUsernames.github)
    setGithubToken(user?.oAuthAccessToken);

  }

  useEffect(async () => {
    const response = await getGithubUser();
    const user = response.data;

    setGithubId(user?.oAuthUsernames.github)
    setGithubToken(user?.oAuthAccessToken);

    while (response.status === 404) {
      getUserDetails()
    }

  }, [])

  // useEffect(async () => {
  //   try {
  //     const hashUrl = `https://api.github.com/repos/${githubId}/${rep}/commits`
  //     const res = await axios.get(hashUrl)
  //     if (res.status === 200) {
  //       setCommitHash(res.data[0].sha)
  //       console.log("Commit Response" + res.data[0])
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [rep, githubId])

  // useEffect(async () => {
  //   try {
  //     const hashUrl = `https://api.github.com/repos/${githubId}/${rep}/commits`;

  //     const config = {
  //       headers: {
  //         'Authorization': `token ${githubToken}`,
  //       }
  //     };

  //     const res = await axios.get(hashUrl, config);

  //     if (res.status === 200) {
  //       setCommitHash(res.data[0].sha);
  //       console.log("Commit Response", res.data[0]);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [rep, githubId]);


  let intervalId = null;

  console.log("Commit Hash", commitHash)
  async function startTesting(commitHash) {
    // code to make the API call and check the response code
    console.log("Testing Func Commit Hash", commitHash)
    const res = await getTesting(commitHash)
    console.log("Start testing res ->" + res?.data);

    console.log(res)
    if (res?.data.app?.testResult === true || res?.data.app?.testResult === false) {
      clearInterval(intervalId);
      console.log("Testing Data" + res.data);
      setLoading(false)
      setTestData(res.data)
    }
  }

  useEffect(() => {
    setTestData(null)
  }, [])
  console.log("useState Data", testData);

  const handleChange = (event) => {
    setRep(event.target.value);
    setIsCheck(true)
    // getHash()
  };

  // const postContainer = async () => {
  //   setLoading(true)
  //   try {
  //     if (isCheck === true) {
  //       console.log("GiHubId", githubId)
  //       const res = await postControlDocker(githubToken, githubId, rep, commitHash)
  //       console.log("Post Container reponse>>" + res);
  //       console.log("container", res.data.containerId)
  //       setContainerId(res.data.containerId)
  //       setTestRunId(res.data.testRunId)
  //       console.log("Status -> ", JSON.stringify(res))
  //       if (res.status === 200) {
  //         console.log("Started Testing")
  //         intervalId = setInterval(() => startTesting(), 20000);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  const jpegOriginal = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = '2022-04-jpegd'
      const commitHash = 'acdd27e466e98fc6db9ab4b6b9b1b12e2a7e61a7'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }
  const jpegModified = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'decoder-jpeg'
      const commitHash = '465d3c764eb4be84f676ddfb2c00fd5ed333ebfd'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }
  const nftOriginal = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'NFT-Marketplace-Auction'
      const commitHash = '3cb0800ca61ea8655763d91e8c29aa1017cffbe0'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }
  const nftModified = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'decoder-NFT-Marketplace-Auction'
      const commitHash = '55d36e8f8bfd34d01e2d5fa0f46569c5e48ccd66'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }
  const candyFarmingOriginal = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'candy-farming'
      const commitHash = '1a328fca8ff46dd34a54f688371b71e38fac5961'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }
  const candyFarmingModified = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'decoder-candy-farming'
      const commitHash = 'cd7e238d1f841d7900a87730b50fa143e5707e87'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }

  const ensOriginal = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = 'main-2023-04-ens'
      const commitHash = '5f8cf8ad58bb5b939a5074ae12b7b06e82155013'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }

  const ensModified = async () => {
    setLoading(true)

    // setIsCheck(true)
    try {
      // if (isCheck === true) {
      // console.log("GiHubId", githubId)
      const githubToken = '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'
      const githubId = 'itsshantanu'
      const rep = '2023-04-ens'
      const commitHash = 'df507997b0cddc9bce9a351a8980177e2821f3c4'
      const res = await postControlDocker(githubToken, githubId, rep, commitHash)
      console.log("Post Container reponse>>" + res);
      console.log("container", res.data.containerId)
      setContainerId(res.data.containerId)
      setTestRunId(res.data.testRunId)
      setCommitHash(commitHash)
      console.log("Commit Hash Inside Function", commitHash)
      console.log("Status -> ", JSON.stringify(res))
      if (res.status === 200) {
        console.log("Started Testing")
        intervalId = setInterval(() => startTesting(commitHash), 20000);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  }

  // console.log("TestRunId", testRunId)
  let intervalIdDecoder = null;
  if (testData?.app?.testResult === false) {
    async function startDecoding() {
      const res = await getDecoding(testRunId)
      console.log("Response ->> ", res)
      if (res?.data?.results[0]?.decoded !== undefined) {
        clearInterval(intervalIdDecoder);
        console.log("Decoding Data", res.data);
        setDecoderLoading(false)
        setDecoderData(res?.data)
        // setDecoderData(res?.data?.results[0]?.decoded)
        // setDecoderTx(res?.data?.results[0]?.txHash)
        setDecoderLoading(false)
      }
    }
    intervalIdDecoder = setInterval(startDecoding, 2000);
  }

  console.log("Set Decoder Data", decoderData)
  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    textAlign: "center",
    width: "30%",
  };

  const tdStyle = {
    border: "1px solid #000",
    background: "white",
    padding: "5px",
    width: '10px'
  };

  const thStyle = {
    border: "1px solid #e8e8e8",
    background: "#e8e8e8",
    color: "white",
    padding: "5px",
  };

  const pass = {
    height: "25px",
    width: "25px",
    background: "#1e8e3e",
    borderRadius: "50%",
    display: "inline-block",
  }
  const fail = {
    height: "25px",
    width: "25px",
    background: "#ea4335",
    borderRadius: "50%",
    display: "inline-block",
  }

  function Dots(string, limit) {
    var dots = "...";
    if (string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  const handleTestClick = () => {
    if (selectedOption === "jpegOriginal") {
      jpegOriginal();
    } else if (selectedOption === "jpegModified") {
      jpegModified();
    } else if (selectedOption === "nftOriginal") {
      nftOriginal();
    } else if (selectedOption === "nftModified") {
      nftModified();
    } else if (selectedOption === "candyOriginal") {
      candyFarmingOriginal();
    } else if (selectedOption === "candyModified") {
      candyFarmingModified();
    } else if (selectedOption === "ensOriginal") {
      ensOriginal();
    } else if (selectedOption === "ensModified") {
      ensModified();
    }
  };

  return (
    <div className="wrapper">
      <div style={{ border: "0px solid black", borderRadius: "10px", padding: "50px 135px", marginTop: "100px", backgroundColor: "#e8e8e8", maxWidth: "440px", marginBottom: '20px' }} >
        <h2>{githubId}</h2>
        <h2>Select a Repo</h2>
        <div >
          {/* <div >
            <div style={{ height: "40px" }} >
              {
                rep ? <h3>Repo: {rep}</h3> : <></>
              }
            </div>
            <br />
            {
              reposArray ? <Box sx={{ minWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Github</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rep}
                    label="Repo"
                    onChange={handleChange}
                    required
                  >
                    {reposArray && reposArray.map((repo, index) => (
                      <MenuItem value={repo?.name} key={index} >{repo?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> : <div style={{ border: "1px solid #b2b2b2", height: "56px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}  >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            }
          </div> */}
          <br />

          <div style={{ display: "flex", justifyContent: 'center' }}>
            <ThemeProvider theme={darkButton}>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                variant="outlined"
                style={{ margin: "5px" }}
                size="small"
                displayEmpty
              >
                <MenuItem value="" disabled>None</MenuItem>
                <MenuItem value="jpegOriginal"> JPEG Original </MenuItem>
                <MenuItem value="jpegModified"> JPEG Modified </MenuItem>
                <MenuItem value="nftOriginal">NFT Marketplace Auction Original</MenuItem>
                <MenuItem value="nftModified">NFT Marketplace Auction Modified</MenuItem>
                <MenuItem value="candyOriginal">Candy Farming Original</MenuItem>
                <MenuItem value="candyModified">Candy Farming Modified</MenuItem>
                <MenuItem value="ensOriginal">ENS Original</MenuItem>
                <MenuItem value="ensModified">ENS Modified</MenuItem>
              </Select>
            </ThemeProvider>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <ThemeProvider theme={darkButton}>
              <LoadingButton
                variant="outlined"
                style={{ margin: "5px" }}
                size="small"
                // onClick={postContainer}
                onClick={handleTestClick}
              >
                Test
              </LoadingButton>
            </ThemeProvider>
            <ThemeProvider theme={darkButton}>
              <LoadingButton
                variant="outlined"
                style={{ margin: "5px" }}
                size="small"
              >
                Deploy
              </LoadingButton>
            </ThemeProvider>
          </div>



          {/* <div style={{ marginTop: '2rem' }}>
            <h4>JPEG</h4>
            <div style={{ display: "flex", justifyContent: 'center' }}>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={jpegOriginal}
                >
                  JPEG Original
                </LoadingButton>
              </ThemeProvider>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={jpegModified}
                >
                  JPEG Modified
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div> */}

          {/* <div style={{ marginTop: '2rem' }}>
            <h4>NFT Marketplace Auction</h4>
            <div style={{ display: "flex", justifyContent: 'center' }}>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={nftOriginal}
                >
                  NFT Marketplace Auction Original
                </LoadingButton>
              </ThemeProvider>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={nftModified}
                >
                  NFT Marketplace Auction Modified
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div> */}

          {/* <div style={{ marginTop: '2rem' }}>
            <h4>Candy Farming</h4>
            <div style={{ display: "flex", justifyContent: 'center' }}>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={candyFarmingOriginal}
                >
                  Candy Farming Original
                </LoadingButton>
              </ThemeProvider>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={candyFarmingModified}
                >
                  Candy Farming Modified
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div> */}

          {/* <div style={{ marginTop: '2rem' }}>
            <h4>ENS</h4>
            <div style={{ display: "flex", justifyContent: 'center' }}>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={ensOriginal}
                >
                  ENS Original
                </LoadingButton>
              </ThemeProvider>
              <ThemeProvider theme={darkButton}>
                <LoadingButton
                  variant="outlined"
                  style={{ margin: "5px" }}
                  size="small"
                  onClick={ensModified}
                >
                  ENS Modified
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div> */}
          <br />
          {loading ? <> <CircularProgress /> <h4>Test Running</h4></> :
            <>
              {console.log(testData?.app.testResult)}
              {
                testData !== null ?
                  <>
                    {testData?.app.testResult ? <h4>All Test Passed</h4> : <h4>Test Failed</h4>}
                  </> : ""
              }
            </>}

        </div>
      </div>

      {testData !== null ?
        <>
          <table style={tableStyle}>
            <tbody>
              <tr>
                <th style={thStyle} >Status</th>
                <th style={thStyle} >Test Name</th>
                <th style={thStyle} >Test Path</th>
                <th style={thStyle} >File Name</th>
                <th style={thStyle} >Transactions</th>
                <th style={thStyle} >Reasons</th>
              </tr>
              {testData?.tests.map(({ status, testName, testId, fileName, transactions, nodeId }) => (
                <tr >
                  <td style={tdStyle}>{status === "passed" ? <div style={pass}></div> : <div style={fail}></div>}</td>
                  <td style={tdStyle}>{testName.at(-1)}</td>
                  <td style={tdStyle}>{testName.slice(0, -1).join('-')}</td>
                  <td style={tdStyle}>{fileName}</td>
                  <td style={tdStyle}>
                    {transactions.map((transaction, index) => (
                      <a key={index} href={`https://explorer.alpha.buildbear.io/${nodeId}/tx/${transaction}`}>
                        <td style={tdStyle}>
                          {Dots(transaction, 20)}
                        </td>
                      </a>
                    ))}
                  </td>
                  <td style={tdStyle}>
                    {status === "passed" ? (
                      "Test Passed, so no reason"
                    ) : decoderLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {decoderData?.results?.map((data, index) => {
                          if (data.txHash === transactions[transactions.length - 1]) {
                            return (
                              <div key={index}>
                                <h3>Tx Hash: <i>{data.txHash}</i></h3>
                                {data.decoded.split('\n').map((line, index) => (
                                  <h4 key={index}>
                                    {line}
                                    <br />
                                  </h4>
                                ))}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </> : ""}

    </div>
  )
}

export default AppContainerList
