import React, { createContext, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Admin from "../pages/Admin";
import GoogleLogin from "../pages/GoogleLogin";
import GithubLogin from "../pages/GithubLogin";
import PageNotFound from "../pages/404";
import { initialState, reducer } from "../store/reducer/home";
import "./App.css";
import CreateNode from "../pages/CreateNode";
import ApiKey from "../pages/ApiKey";
import { Helmet } from "react-helmet";

export const AuthContext = createContext();


function Home() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Helmet>
        <title>Home || BuildBear Testnet</title>
      </Helmet>
      {/* <a href="http://bit.ly/3PE6p2q" target="_blank" rel="noreferrer">
        <h4 className="banner">Hi, You are currently using the Upgraded version of BuildBear.  More on this over here</h4>
      </a> */}
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />

        <Route path="/login/github" element={<GithubLogin />} />
        <Route path="/login/google" element={<GoogleLogin />} />
        <Route path="/create" element={<CreateNode />} />
        {
          process.env.REACT_APP_BASE_URL !== "buildbear.io" && <Route path="/apikey" element={<ApiKey />} />
        }
        {state.isAdmin ? <Route path="/admin" element={<Admin />} /> : ''}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default Home;
