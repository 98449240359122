import { Routes, Route } from "react-router-dom"

// import Landing from "../Landing";
import Explorer from "../eth-scan/App";
import Faucet from "../faucet/App";
import Home from "../home/App";
import LandingPage from "../landing/App";
import BuildBearApp from "../bb-app/App";

export const LandingRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<LandingPage />} />
    </Routes>
  );
};

export const HomeRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
    </Routes>
  );
};


export const ExplorerRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Explorer />} />
    </Routes>
  );
};

export const FaucetRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Faucet />} />
    </Routes>
  );
};

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<BuildBearApp />} />
    </Routes>
  );
};
