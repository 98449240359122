import "../styles/ContainerList.css";
import {
    getUser,
    getAllUserContainers,
    getRpcUrl,
    getScanUrl,
    getFaucetUrl,
    postSurvey,
    // restartContainer,
    deleteContainer,
    // updateContainer,
    uploadArtifactArray,
} from "../api/home";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../home/App";
import { useContext } from "react";
import { CircularProgress } from "@mui/material";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
// import DeleteIcon from "@mui/icons-material/Delete";
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
// import LaunchIcon from "@mui/icons-material/Launch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import { ethers } from "ethers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

//advance
import handleArtifactParse from "../utils/artifactUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DropFileInput from "./Drop-File/DropFileInput";
import LinearWithValueLabel from "./Drop-File/ParsingIndicator";
import UploadIndicator from "./Drop-File/UploadIndicator";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Tabs, Tab, AppBar } from "@mui/material";

import Modal from "@mui/material/Modal";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
// import "highlight.js/styles/github.css";
import "highlight.js/styles/agate.css";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import ReactGA from "react-ga4";
import { ReactComponent as Delete } from "../assets/Delete.svg";
import { ReactComponent as Metamask } from "../assets/metamask.svg";
import { ReactComponent as Faucet } from "../assets/faucet.svg";
import Binance from "../assets/chain/binance.svg";
import Etherium from "../assets/chain/etherium.svg";
// import Polygon from "../assets/chain/poly.svg"
import Poll from "../assets/chain/poll.svg";
import Arbit from "../assets/chain/arbit.svg";
import Sepoli from "../assets/chain/sepolia.svg";
import Fandom from "../assets/chain/fandom.svg";
import Polygon from "../assets/chain/polygon.svg";
import Optimism from "../assets/chain/optimism.svg";
import Avalanche from "../assets/chain/avalanche.svg";
import Kava from "../assets/chain/kava.svg";


// Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
hljs.registerLanguage("javascript", javascript);
// import { boolean } from "joi";

// const config = {
//   checked: false,
//   options: {
//     hardhat: {
//       getStackTraceFailuresCount: true,
//       addCompilationResult: true,
//       impersonateAccount: true,
//       intervalMine: true,
//       getAutomine: true,
//       stopImpersonatingAccount: true,
//       reset: true,
//       setLoggingEnabled: true,
//       setMinGasPrice: true,
//       dropTransaction: true,
//       setBalance: true,
//       setCode: true,
//       setNonce: true,
//       setStorageAt: true,
//       setNextBlockBaseFeePerGas: true,
//       setCoinbase: true,
//       mine: true,
//     },
//     evm: {
//       increaseTime: true,
//       setNextBlockTimestamp: true,
//       mine: true,
//       revert: true,
//       snapshot: true,
//       setAutomine: true,
//       setIntervalMining: true,
//       setBlockGasLimit: true,
//     },
//     extra: {
//       overrideGas: false,
//     }
//   },
// };

const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: "rgba(255,255,255,0.87) !important",
        fontSize: 13,
    },
}));

const networkRpc = {
    1: ["Ethereum Mainnet", "https://rpc.ankr.com/eth", Etherium],
    56: ["Binance Smart Chain", "https://rpc.ankr.com/bsc", Binance],
    137: ["Polygon Mainnet", "https://rpc.ankr.com/polygon", Polygon],
    80001: ["Polygon Testnet", "https://rpc.anrkr.com/polygon_mumbai", Polygon],
    5: ["Goerli Testnet", "https://rpc.ankr.com/eth_goerli", Etherium],
    11155111: ["Sepolia Testnet", "https://rpc.sepolia.org/", Etherium],
    10: ["Optimism Mainnet", "https://mainnet.optimism.io", Optimism],
    42161: ["Arbitrum Mainnet", "https://arb1.arbitrum.io/rpc", Arbit],
    421613: ["Arbitrum Goerli", "https://goerli-rollup.arbitrum.io/rpc", Arbit],
    250: ["Fantom Mainnet", "https://rpc.fantom.network", Fandom],
    4002: ["Fantom Testnet", "https://rpc.ankr.com/fantom_testnet", Fandom],
    43114: ["Avalanche Network", "https://rpc.ankr.com/avalanche", Avalanche],
    2222 : ["Kava EVM Co-Chain", "https://evm.kava.io", Kava],
};

function getDuration(epochStartTime) {
    const millisecondsInADay = 6.66e7;
    let fullDays, fullHours, fullMins;
    const durationInSec = Date.now() - new Date(epochStartTime);
    fullDays = Math.floor(durationInSec / millisecondsInADay);
    let duration = `${fullDays} day${fullDays === 1 ? "" : "s"}`;
    if (fullDays < 1) {
        const millisecondsInAnHour = 3.6e6;
        fullHours = Math.floor(durationInSec / millisecondsInAnHour);
        duration = `${fullHours} hour${fullHours === 1 ? "" : "s"}`;
    }
    if (fullHours < 1) {
        const millisecondsInAMin = 60000;
        fullMins = Math.floor(durationInSec / millisecondsInAMin);
        duration = `${fullMins} minute${fullMins === 1 ? "" : "s"}`;
    }
    if (fullMins < 1) {
        duration = `Less than a minute`;
    }

    return duration;
}
const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "black",
    },
}));

const theme = createTheme({
    components: {
        MuiTabPanel: {
            styleOverrides: {
                root: {},
                wrapper: {
                    padding: "0px",
                },
            },
        },
    },
});

function ContainerList() {
    const [open, setOpen] = useState(false);
    const [parseProgress, setParseProgress] = useState(0);
    const [isParsing, setIsParsing] = useState(false);
    const [artifactUploading, setArtifactUploading] = useState(false);
    const artifactUploadingNodeHash = useRef();
    const [artifactError, setArtifactError] = useState(null);
    const submittedFiles = useRef();

    const [openModal, setOpenModal] = useState(false);
    const [hear, setHear] = useState("");
    const [profile, setProfile] = useState("");
    const [use, setUse] = useState("");

    const codeSnippetNodeId = useRef();
    const codeSnippetChainId = useRef();
    const codeRef = useRef(null);
    const codeRef2 = useRef(null);
    const codeRef3 = useRef(null);

    const [openCodeSnippet, setOpenCodeSnippet] = useState(false);
    const handleOpenCodeSnippet = () => setOpenCodeSnippet(true);
    const handleCloseCodeSnippet = () => setOpenCodeSnippet(false);

    const [otherSource, setOtherSource] = useState("");
    const [otherHear, setOtherHear] = useState(false);
    const [source, setSource] = useState("");
    const [otherSourceQ2, setOtherSourceQ2] = useState("");
    const [otherHearQ2, setOtherHearQ2] = useState(false);
    const [sourceQ2, setSourceQ2] = useState("");
    const [otherSourceQ3, setOtherSourceQ3] = useState("");
    const [otherHearQ3, setOtherHearQ3] = useState(false);
    const [sourceQ3, setSourceQ3] = useState("");
    const [showRcp, setShowRpc] = useState(false);

    const [live, setLive] = useState(false);

    const handleChange1 = (event) => {
        const value = event.target.value;
        setHear(value);
        setOtherHear(value === "Other");
    };

    const handelChangeOther = (event) => {
        const eName = event.target.value.replace(/[^a-zA-Z0-9\s]/gi, "");
        setOtherSource(eName);
    };

    const handleChange2 = (event) => {
        const value = event.target.value;
        setProfile(value);
        setOtherHearQ2(value === "Other");
    };

    const handelChangeOtherQ2 = (event) => {
        const eName = event.target.value.replace(/[^a-zA-Z0-9\s]/gi, "");
        setOtherSourceQ2(eName);
    };

    const handleChange3 = (event) => {
        const value = event.target.value;
        setUse(value);
        setOtherHearQ3(value === "Other");
    };

    const handelChangeOtherQ3 = (event) => {
        const eName = event.target.value.replace(/[^a-zA-Z0-9\s]/gi, "");
        setOtherSourceQ3(eName);
    };
    //  Twitter
    //  Medium
    //  Other

    const classes = useStyles();

    useEffect(() => {
        setSource(otherHear ? otherSource : hear);
    }, [otherHear, otherSource, hear]);

    useEffect(() => {
        setSourceQ2(otherHearQ2 ? otherSourceQ2 : profile);
    }, [otherHearQ2, otherSourceQ2, profile]);

    useEffect(() => {
        setSourceQ3(otherHearQ3 ? otherSourceQ3 : use);
    }, [otherHearQ3, otherSourceQ3, use]);
    async function postForm(source, sourceQ2, sourceQ3) {
        // console.log(">>>:"+source+":"+profile+":"+use)
        try {
            const formRes = postSurvey(source, sourceQ2, sourceQ3);
        } catch (err) {
            // console.log(err);
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ py: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);
    const [valueSub, setValueSub] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeTabSub = (event, newValue) => {
        setValueSub(newValue);
    };

    const handleClickOpen = (e) => {
        setParseProgress(0);
        setIsParsing(false);
        setArtifactError(null);
        submittedFiles.current = null;
        setArtifactUploading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (isParsing !== "parsing" && artifactUploading !== "uploading") {
            setOpen(false);
        }
    };

    // const handleClickOpenModal = () => {
    //   setOpenModal(true);
    // };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpenModal(false);
        }
        postForm(source, sourceQ2, sourceQ3);
    };

    // Update open
    // const [submitting, setSubmitting] = useState(false);
    // const formikRef = useRef(null);
    // const handleClickOpen = (e) => {
    //   setOpen(true);
    // };

    // const handleClose = () => {
    //   setOpen(false);
    // };

    const handleArtifactSubmit = (nodeid) => {
        if (!submittedFiles.current) {
            // console.log("Empty");
            setArtifactError("Please upload a folder!");
            return;
        }
        setArtifactUploading(false);
        setArtifactError(null);
        setIsParsing("parsing");

        
        handleArtifactParse(
            submittedFiles.current,
            (e) => onAbiArray(e, nodeid),
            onParsingProgress
        );
    };

    const onFileChange = (files) => {
        submittedFiles.current = files;
        setParseProgress(0);
        setIsParsing(false);
        setArtifactError(null);
        setArtifactUploading(false);
    };

    function onAbiArray(abiArray, nodeid) {
        // console.log("Successfull parsed", abiArray);
        setIsParsing("parsed");
        if (abiArray?.length === 0) {
            setArtifactError(
                "Couldn't find any artifact, did you upload the correct folder?"
            );
            return;
        }
        uploadArtifact(abiArray, nodeid);
    }

    async function uploadArtifact(abiArray, nodeid) {
        setArtifactUploading("uploading");
        
        const res = await uploadArtifactArray(
            abiArray,
            nodeid
        );

        if (res.status !== 200) {
            setArtifactUploading("error");
            setArtifactError("Error during upload, Please try again later.");
            return;
        }
        setArtifactUploading("uploaded");
    }

    function onParsingProgress(progress) {
        setParseProgress(progress);
    }

    const explorerEvent = () => {
        ReactGA.event({
            category: "BuildBear category",
            action: "Explorer on Node",
        });
    };
    const faucetEvent = () => {
        ReactGA.event({
            category: "BuildBear category",
            action: "Faucet on Node",
        });
    };

    // const [, setDialogOpen] = useState(false);

    // async function onSubmit(event, checked, nodeId) {
    //   try {
    //     setSubmitting(true);
    //     formikRef.current.setSubmitting(true);
    //     const config = formikRef.current.values;

    //     const sanitizedConfig = JSON.parse(JSON.stringify(config));

    //     if (checked !== undefined) {
    //       sanitizedConfig.checked = checked;
    //     }

    //     console.log(sanitizedConfig);
    //     const res = await updateContainer(sanitizedConfig, nodeId);
    //     if (res.err) {
    //       //TODO: Handle Error
    //       console.log(res.err);
    //       alert("Sorry, couldn't update");
    //     } else {
    //       console.log(res);
    //       if (res.status === 409) {
    //         setDialogOpen(true);
    //       } else {
    //         console.log("Updated");
    //         formikRef.current.setFieldValue("checked", false);
    //         alert("Hurray! Your node is being updated. It will be updated shortly.");
    //         // handleDialogClose={handleDialogClose};
    //         setSubmitting(false);
    //         formikRef.current.setSubmitting(false);
    //         // useNavigate.push("/");
    //         navigate("/")
    //         return;
    //       }
    //     }
    //   } catch (err) {
    //     //TODO: Handle Error
    //     console.log(err);
    //     alert("Sorry, couldn't update");
    //   } finally {
    //     setSubmitting(false);
    //     formikRef?.current?.setSubmitting(false);
    //   }
    // }

    // const handleDialogClose = (e) => {
    //   if (e.target.id === "create") {
    //     // formikRef.current.setFieldValue("checked", true);
    //     onSubmit(e, true);
    //   }
    //   setDialogOpen(false);
    // };

    //Update close

    const darkButton = createTheme({
        palette: {
            primary: {
                main: "#000",
            },
        },
    });

    function setButtonLoading(hash, buttonName, loading, disabled) {
        const newContainers = freshContainers.current.map((container) => {
            if (container.nodeId === hash) {
                container[buttonName].loading = loading;
                container[buttonName].disabled = disabled;
            }
            return container;
        });
        freshContainers.current = newContainers;
        setContainers(newContainers);
    }
    // async function restart(hash) {
    //   try {
    //     setButtonLoading(hash, "restart", true, true);
    //     setButtonLoading(hash, "delete", false, true);
    //     const res = await restartContainer(hash);
    //     if (res.err) {
    //       console.log("Error during restart:", res.err);
    //       alert("Sorry there was an error. Try again Later.");
    //     } else {
    //       if (res.status === 200) {
    //         console.log(`Restarted ${hash} Successfully`, res.data.uptime);
    //         const newContainers = freshContainers.current.map((container) => {
    //           if (container.nodeId === hash) {
    //             container.uptime = res.data.uptime;
    //           }
    //           return container;
    //         });
    //         freshContainers.current = newContainers;
    //         setContainers(newContainers);
    //         alert("Restarted successfully.");
    //       } else {
    //         console.log("Couldn't Restart");
    //         alert("Sorry there was an error. Try again Later.");
    //       }
    //     }
    //   } catch (err) {
    //     console.log("Err", err);
    //     console.log("Couldn't Restart");
    //     alert("Sorry there was an error. Try again Later.");
    //   } finally {
    //     setButtonLoading(hash, "restart", false, false);
    //     setButtonLoading(hash, "delete", false, false);
    //   }
    // }

    async function deleteNode(hash) {
        let couldNotDelete = false;
        try {
            setButtonLoading(hash, "restart", false, true);
            setButtonLoading(hash, "delete", true, true);
            const res = await deleteContainer(hash);
            if (res.err) {
                couldNotDelete = true;
                // console.log("Error during delete:", res.err);
                // alert("Sorry there was an error. Try again Later.");
                toast.error("Sorry there was an error. Try again Later.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                if (res.status === 200) {
                    // console.log(`Deleted ${hash} Successfully`);
                    const newContainers = freshContainers.current.filter(
                        (container) => {
                            return container.nodeId !== hash;
                        }
                    );
                    freshContainers.current = newContainers;
                    setContainers(newContainers);
                    // alert("Deleted successfully.");
                    toast.success("Deleted successfully.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    // document.location.reload();
                } else {
                    couldNotDelete = true;
                    // console.log("Couldn't Delete");
                    // alert("Sorry there was an error. Try again Later.");
                    toast.error("Sorry there was an error. Try again Later.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        } catch (err) {
            couldNotDelete = true;
            // console.log("Err", err);
            // console.log("Couldn't delete");
            // alert("Sorry there was an error. Try again Later.");
            toast.error("Sorry there was an error. Try again Later.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            if (couldNotDelete) {
                setButtonLoading(hash, "restart", false, false);
                setButtonLoading(hash, "delete", false, false);
            }
        }
    }

    function getStatusColor(status) {
        switch (status) {
            case "Active":
                return "success";
            case "Live":
                return "success";
            case "live":
                return "success";
            case "Initializing":
                return "warning";
            case "Deleted due to inactivity":
                return "error";
            case "Killed":
                return "error";
            case "Deleted":
                return "error";
            case "Error":
                return "error";
            case "Started":
                return "started";
            case "Stopped":
                return "stopped";
            default:
                return "info";
        }
    }

    async function checkIfNodeSpawned() {
        console.log("Checking if node spawned");
        // eslint-disable-next-line
        freshContainers.current.map((container) => {
            const milliInSecond = 1000;
            const durationInSec =
                (Date.now() - new Date(container.dateOfCreation)) /
                milliInSecond;
            // console.log("duration", durationInSec);
            if (durationInSec <= 40) {
                checkIfNodeInitialized(container);
            } else {
                if (container.status !== "Deleted due to inactivity") {
                    checkIfNodeLive(container);
                }
            }
        });
    }

    function replaceStr(str) {
        var replace = str.replaceAll("_", " ");
        return replace.slice(0, -9);
    }

    async function checkIfNodeInitialized(container) {
        const rpcUrl = getRpcUrl(container.nodeId);
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        function setIntervalImmediately(func, ...args) {
            func();
            return setInterval(func, ...args);
        }
        const checkInterval = setIntervalImmediately(async () => {
            console.log("Running every 5 seconds");
            // console.log(container)
            // const test = containers.at(-1).status
            // test == "live"
            try {
                const { chainId } = await provider.getNetwork();
                // console.log(chainId);
                // console.warn("Node Successfuly spawned!!");
                setLive(true);
                const newContainers = freshContainers.current.map(
                    (containerElem) => {
                        // console.log("ContainerElem", containerElem)
                        // console.log("Container", container)
                        if (containerElem.nodeId === container.nodeId) {
                            // console.log("inside if")
                            containerElem.status = "Live";
                        }
                        return containerElem;
                    }
                );
                freshContainers.current = newContainers;
                setContainers(newContainers);
                clearInterval(checkInterval);
                setButtonLoading(container.nodeId, "metamask", false, false);
                setButtonLoading(container.nodeId, "delete", false, false);
                setButtonLoading(container.nodeId, "restart", false, false);
            } catch (err) {
                // console.log(err);
                const newContainers = freshContainers.current.map(
                    (containerElem) => {
                        if (containerElem.hash === container.nodeId) {
                            containerElem.status = "Initializing";
                        }
                        return containerElem;
                    }
                );
                freshContainers.current = newContainers;
                setContainers(newContainers);
                setButtonLoading(container.nodeId, "metamask", false, true);
                setButtonLoading(container.nodeId, "delete", false, true);
                setButtonLoading(container.nodeId, "restart", false, true);
            }
        }, 5000);

        const milliSecsRemainTillFortySec =
            40000 - (Date.now() - new Date(container.dateOfCreation));
        setTimeout(async () => {
            try {
                // const rpcUrl = getRpcUrl(container.nodeId);
                // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
                // const { chainId } = await provider.getNetwork();
                // console.log("Node Successfuly spawned!!");
            } catch (err) {
                clearInterval(checkInterval);
                // console.log("Couldn't connect to node yet");
                const newContainers = freshContainers.current.map(
                    (containerElem) => {
                        if (containerElem.hash === container.nodeId) {
                            containerElem.status = "Error";
                        }
                        return containerElem;
                    }
                );
                freshContainers.current = newContainers;
                setContainers(newContainers);
                setButtonLoading(container.nodeId, "metamask", false, true);
                setButtonLoading(container.nodeId, "delete", false, false);
                setButtonLoading(container.nodeId, "restart", false, true);
            }
        }, milliSecsRemainTillFortySec);
    }

    async function checkIfNodeLive(container) {
        try {
            // const rpcUrl = getRpcUrl(container.nodeId);
            // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            // const { chainId } = await provider.getNetwork();
            const newContainers = freshContainers.current.map(
                (containerElem) => {
                    if (containerElem.hash === container.nodeId) {
                        containerElem.status = "Live";
                    }
                    return containerElem;
                }
            );
            freshContainers.current = newContainers;
            setContainers(newContainers);
        } catch (err) {
            const newContainers = freshContainers.current.map(
                (containerElem) => {
                    if (containerElem.hash === container.nodeId) {
                        containerElem.status = "Error";
                    }
                    return containerElem;
                }
            );
            freshContainers.current = newContainers;
            setContainers(newContainers);
            setButtonLoading(container.nodeId, "metamask", false, true);
            setButtonLoading(container.nodeId, "delete", false, false);
            setButtonLoading(container.nodeId, "restart", false, true);
        }
    }
    // const updatingNodeHash = useRef();
    const freshContainers = useRef();
    const { state, dispatch } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [containers, setContainers] = useState([]);
    // const [showRpc, setShowRpc] = useState()
    const [mounted, setMounted] = useState(true);
    // const navigate = useNavigate();
    const history = useNavigate();

    const [metamaskLock, setMetamaskLock] = useState();

    async function checkMetamaskLock() {
        const { ethereum } = window;
        const accounts = await ethereum._metamask.isUnlocked();
        if (accounts) {
            setMetamaskLock(true);
        } else {
            setMetamaskLock(false);
        }
        // console.log(accounts);
    }

    useEffect(() => {
        checkMetamaskLock();
    }, []);

    useEffect(() => {
        document.addEventListener("visibilitychange", checkMetamaskLock);
        // console.log(checkMetamaskLock)
        return () =>
            document.removeEventListener("visibilitychange", checkMetamaskLock);
    }, []);

    // let intervalId = null;
    useEffect(() => {
        async function getContainers(hash) {
            const response = await getAllUserContainers();
            // if (response.data.at(-1).status === 'live') {
            //   console.log(intervalId, "Clear Interval")
            //   clearInterval(intervalId);
            // }
            // intervalId = setInterval(() => getAllUserContainers(), 5000);
            console.log(response);
            // console.log(response.data.at(-1).status)
            if (response.status === 401) {
                dispatch({
                    type: "LOGOUT",
                });
                // useNavigate.replace("/login");
                history("/login", { replace: true });
            } else if (response.err) {
                setContainers([]);
                freshContainers.current = [];
            } else {
                const data = response.data.map((container) => {
                    container.restart = {
                        loading: false,
                        disabled: false,
                    };
                    container.delete = {
                        loading: false,
                        disabled: false,
                    };
                    container.metamask = {
                        loading: false,
                        disabled: false,
                    };
                    container.nodeId = container.nodeId;
                    container.showRpc = false;
                    return container;
                });
                // console.log(data);
                setContainers(data);
                freshContainers.current = data;
                checkIfNodeSpawned();
            }
            setLoading(false);
        }
        if (mounted) {
            getContainers();
        }

        return () => {
            setMounted(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dispatch, history, mounted]);
    // console.log(containers.at(-1).status)
    // containers.at(-1).status == "live"
    useEffect(() => {
        async function getUserDetails() {
            const response = await getUser();
            const check = response.data.stats.surveyCompleted;
            if (check === false) {
                setOpenModal(true);
            } else if (check === true) {
                setOpenModal(false);
            } else {
            }
        }
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => { }, []);

    useEffect(() => { }, []);

    async function connectMetaMask(nodeHash, rpcUrl, scanUrl) {
        if (!window.ethereum) {
            // alert("Metamask not found. Please Install it.");
            toast.error("Metamask not found. Please Install it.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const { chainId } = await provider.getNetwork();
        let formattedName = null;
        try {
            formattedName = nodeHash.split("_").slice(0, -1).join(" ");
        } catch (err) {
            // console.log("Error during name formating");
        }

        if (!formattedName) {
            formattedName = nodeHash;
        }

        window.ethereum
            .request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                        chainName: `BuildBear ${formattedName}`,
                        chainId: ethers.utils.hexlify(chainId),
                        rpcUrls: [rpcUrl],
                        blockExplorerUrls: [scanUrl],
                        nativeCurrency: {
                            name: "BB Ether",
                            symbol: "BB ETH",
                            decimals: 18,
                        },
                    },
                ],
            })
            .then((data) => {
                // console.log(data);
                if (data === null) {
                    // alert("Successfully added.");
                    toast.success("Successfully added.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    checkMetamaskLock();
                } else {
                    // alert("Unable to add.");
                    toast.error("Unable to add.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((e) => {
                if (e.code === 4902) {
                    // console.log("network is not available, add it");
                } else {
                    // console.log("could not set network");
                }
                // alert("Unable to add network");
                toast.error("Unable to add network", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // console.log(e);
            });
    }
    // console.log(containers[0].status)
    let test = containers.length;
    console.log(test);
    let count = 0;
    for (let i = 0; i < containers.length; i++) {
        if (containers[i].status === "removed") {
            count++;
        }
    }
    if (loading) {
        return (
            <div className="wrapper">
                <CircularProgress sx={{ color: "#000" }} />
            </div>
        );
    } else if (containers.length === 0 || count === containers.length) {
        return (
            <div className="wrapper">
                <div className="create-new">
                    <Typography
                        className="containerHash"
                        variant="h6"
                        style={{ textAlign: "center", color: "#403F3F" }}
                    >
                        <h1 style={{ marginBottom: "30px" }}>
                            Lets get started
                        </h1>
                        Welcome to BuildBear! <br />
                        {/* Create your first endpoint and then sit back and relax. */}
                        <div className="create-endpoint-btn">
                            <Link to="/create">
                                <h3>+ Create Your First Private Testnet</h3>
                            </Link>
                        </div>
                    </Typography>
                </div>
                <div>
                    {/* <Button onClick={handleClickOpenModal}>Open select dialog</Button> */}
                    <Dialog
                        disableEscapeKeyDown
                        open={openModal}
                        onClose={handleCloseModal}
                    >
                        <DialogTitle className="form-header">
                            Hi, we would like to know a little bit more about
                            you:
                        </DialogTitle>
                        <hr style={{}} />
                        <DialogTitle sx={{ fontSize: 20, m: 1 }}>
                            1. Where did you hear about BuildBear ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={hear}
                                        onChange={handleChange1}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Discord"}>
                                            Discord
                                        </MenuItem>
                                        <MenuItem value={"Telegram"}>
                                            Telegram
                                        </MenuItem>
                                        <MenuItem value={"Twitter"}>
                                            Twitter
                                        </MenuItem>
                                        <MenuItem value={"Medium"}>
                                            Medium
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>

                        {otherHear ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOther}
                                                // helperText="Incorrect entry."
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}

                        <DialogTitle sx={{ fontSize: 20, m: 1 }}>
                            2. What is your current profile ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={profile}
                                        onChange={handleChange2}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Student"}>
                                            Student
                                        </MenuItem>
                                        <MenuItem
                                            value={"Professional Developer"}
                                        >
                                            Professional Developer
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                "Developer Relationship Manager (DevRel)"
                                            }
                                        >
                                            Developer Relationship Manager
                                            (DevRel)
                                        </MenuItem>
                                        <MenuItem value={"CTO"}>CTO</MenuItem>
                                        <MenuItem
                                            value={"Management (CEO/CMO)"}
                                        >
                                            Management (CEO/CMO)
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                "Quality Assurance (QA Testing)"
                                            }
                                        >
                                            Quality Assurance (QA Testing)
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>

                        {otherHearQ2 ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOtherQ2}
                                                // helperText="Incorrect entry."
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}
                        <DialogTitle sx={{ fontSize: 20, ml: 1 }}>
                            3. What is your primary use of buildbear ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={use}
                                        onChange={handleChange3}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Just exploring"}>
                                            Just exploring
                                        </MenuItem>
                                        <MenuItem
                                            value={"Testing Smart Contracts"}
                                        >
                                            Testing Smart Contracts
                                        </MenuItem>
                                        <MenuItem value={"Testing dApps"}>
                                            Testing dApps
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        {otherHearQ3 ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOtherQ3}
                                            // helperText="Incorrect entry."
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}
                        <DialogActions>
                            {(source && profile && use) === "" ? (
                                <DarkToolTip
                                    title={
                                        "lorem ipsum lorem ipsum lorem ipsum"
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <Button
                                        disabled
                                        onClick={handleCloseModal}
                                        className="form-button-disable"
                                        size="large"
                                        style={{
                                            marginRight: "30px",
                                            marginBottom: "30px",
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </DarkToolTip>
                            ) : (
                                <Button
                                    className="form-button"
                                    size="large"
                                    style={{
                                        marginRight: "30px",
                                        marginBottom: "30px",
                                    }}
                                    onClick={handleCloseModal}
                                >
                                    Submit
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div>
                    {/* <Button onClick={handleClickOpenModal}>Open select dialog</Button> */}
                    <Dialog
                        disableEscapeKeyDown
                        open={openModal}
                        onClose={handleCloseModal}
                    >
                        <DialogTitle className="form-header">
                            Hi, we would like to know a little bit more about
                            you:
                        </DialogTitle>
                        <hr style={{}} />
                        <DialogTitle sx={{ fontSize: 20, m: 1 }}>
                            1. Where did you hear about BuildBear ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={hear}
                                        onChange={handleChange1}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Discord"}>
                                            Discord
                                        </MenuItem>
                                        <MenuItem value={"Telegram"}>
                                            Telegram
                                        </MenuItem>
                                        <MenuItem value={"Twitter"}>
                                            Twitter
                                        </MenuItem>
                                        <MenuItem value={"Medium"}>
                                            Medium
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>

                        {otherHear ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOther}
                                                // helperText="Incorrect entry."
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}

                        <DialogTitle sx={{ fontSize: 20, m: 1 }}>
                            2. What is your current profile ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={profile}
                                        onChange={handleChange2}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Student"}>
                                            Student
                                        </MenuItem>
                                        <MenuItem
                                            value={"Professional Developer"}
                                        >
                                            Professional Developer
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                "Developer Relationship Manager (DevRel)"
                                            }
                                        >
                                            Developer Relationship Manager
                                            (DevRel)
                                        </MenuItem>
                                        <MenuItem value={"CTO"}>CTO</MenuItem>
                                        <MenuItem
                                            value={"Management (CEO/CMO)"}
                                        >
                                            Management (CEO/CMO)
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                "Quality Assurance (QA Testing)"
                                            }
                                        >
                                            Quality Assurance (QA Testing)
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>

                        {otherHearQ2 ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOtherQ2}
                                                // helperText="Incorrect entry."
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}
                        <DialogTitle sx={{ fontSize: 20, ml: 1 }}>
                            3. What is your primary use of buildbear ?
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="form"
                                sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                                <FormControl
                                    required
                                    sx={{ m: 1, minWidth: 450 }}
                                >
                                    <InputLabel id="demo-dialog-select-label">
                                        None
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={use}
                                        onChange={handleChange3}
                                        input={<OutlinedInput label="None" />}
                                    >
                                        <MenuItem value={"Just exploring"}>
                                            Just exploring
                                        </MenuItem>
                                        <MenuItem
                                            value={"Testing Smart Contracts"}
                                        >
                                            Testing Smart Contracts
                                        </MenuItem>
                                        <MenuItem value={"Testing dApps"}>
                                            Testing dApps
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Any other, please share
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        {otherHearQ3 ? (
                            <>
                                <DialogTitle sx={{ fontSize: 18, m: 1 }}>
                                    Other
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <FormControl
                                            required
                                            sx={{ m: 1, minWidth: 450 }}
                                        >
                                            <TextField
                                                id="outlined-error"
                                                label="Other*"
                                                onChange={handelChangeOtherQ3}
                                            // helperText="Incorrect entry."
                                            />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                            </>
                        ) : null}
                        <DialogActions>
                            {(source && profile && use) === "" ? (
                                <DarkToolTip
                                    title={
                                        "lorem ipsum lorem ipsum lorem ipsum"
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <Button
                                        disabled
                                        onClick={handleCloseModal}
                                        className="form-button-disable"
                                        size="large"
                                        style={{
                                            marginRight: "30px",
                                            marginBottom: "30px",
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </DarkToolTip>
                            ) : (
                                <Button
                                    className="form-button"
                                    size="large"
                                    style={{
                                        marginRight: "30px",
                                        marginBottom: "30px",
                                    }}
                                    onClick={handleCloseModal}
                                >
                                    Submit
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
                <div className="endpoint-header">
                    <h4> Dashboard</h4>
                </div>
                <div
                    className="wrapper"
                    style={{
                        // marginRight: "-10px",
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexFlow: "wrap",
                    }}
                >
                    {/* <div style={{display: 'flex',justifyContent: 'space-around', width: '100%', color: '#000', backgroundColor: '#E8E8E8'}}>
            <h3 style={{marginLeft: '-160px'}}>Name</h3>
            <h3>Key</h3>
          </div> */}

                    {containers.map((container, index) => {
                        const createdDuration = `${getDuration(
                            container.dateOfCreation
                        )} ago`;
                        // const uptime = `${getDuration(container.uptime)} ago`;
                        const rpcUrl = getRpcUrl(container.nodeId);
                        const scanUrl = getScanUrl(container.nodeId);
                        const faucetUrl = getFaucetUrl(container.nodeId);
                        let status = container.status
                            ? container.status
                            : "Live";
                        const containerName = container.nodeId;
                        if (status === "removed") {
                            return null;
                        }
                        return (
                            <>
                                {/* <Accordion className="accordion" key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="accSumm">
                      <Typography sx={{ fontWeight: "500" }}>
                        Container {index + 1}
                      </Typography>
                      <div></div>
                      <Typography className="smallFont">
                        {container.hash}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      spacing={2}
                      className="gridContainer"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Typography>RPC URL: </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={8}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography className="smallFont">{rpcUrl}  <IconButton
                            color="primary"
                            onClick={() => {
                              navigator.clipboard.writeText(rpcUrl);
                            }}
                          >
                            <ContentCopyIcon sx={{ fontSize: "15px" }} />
                          </IconButton></Typography>
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>Explorer: </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={8}
                        justifyContent="center"
                        alignItems="center"
                        textAlign={"center"}
                      >
                        <Grid item xs={5}>
                          <a href={scanUrl} style={{ textDecoration: 'none', color: '#000' }} target="_blank" rel="noreferrer noopener">
                            <Typography color="primary" className="smallFont" style={{ color: '#000', display: 'flex' }}>
                              {scanUrl}
                              <i className="fa fa-external-link" style={{ padding: '3px 5px' }}></i>
                            </Typography>
                          </a>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>


                      <Grid item xs={4}>
                        <Typography>Faucet: </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={8}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={5}>
                          <a href={faucetUrl} style={{ textDecoration: 'none', color: '#000' }} target="_blank" rel="noreferrer noopener">
                            <Typography color="primary" className="smallFont" style={{ color: '#000', display: 'flex' }}>
                              {faucetUrl}
                              <i className="fa fa-external-link" style={{ padding: '3px 5px' }}></i>
                            </Typography>
                          </a>
                        </Grid>
                      
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>Created : </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>{createdDuration}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>Uptime since restart: </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>{uptime}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>
                  <Grid item xs={4}>
                    <Typography>Add your network to metamask </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <ThemeProvider theme={darkButton}>
                      <LoadingButton
                          variant="outlined"
                      <Grid item xs={4}>
                        <Typography>Add your network to metamask </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <LoadingButton
                          variant="outlined"
                          // style={{ color: '#000' }}
                          size="small"
                          onClick={() => {
                            connectMetaMask(container.hash, rpcUrl, scanUrl);
                          }}
                        >
                          Add to Metamask
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>Restart your node </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <LoadingButton
                          variant="outlined"
                          style={{ color: '#000' }}
                          size="small"
                          loading={container.restart.loading}
                          disabled={container.restart.disabled}
                          onClick={() => {
                            restart(container.hash);
                          }}
                        >
                          Restart
                          <RestartAltIcon />
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>Delete your node </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <LoadingButton
                          variant="outlined"
                          style={{ color: '#000' }}
                          size="small"
                          loading={container.delete.loading}
                          disabled={container.delete.disabled}
                          onClick={() => {
                            deleteNode(container.hash);
                          }}
                        >
                          Delete
                          <DeleteIcon />
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion> */}

                                <div
                                    style={{
                                        color: "#000",
                                        backgroundColor: "#F4F4F2",
                                        // padding: "35px",
                                        padding: "35px 35px 14px 35px",
                                        width: "610px",
                                        height: "auto",
                                        borderTopLeftRadius: "16px",
                                        borderTopRightRadius: "16px",

                                        marginBottom: "100px",
                                        position: "relative",
                                    }}
                                >
                                    <div
                                        className="  "
                                        style={{
                                            position: "absolute",
                                            bottom: "-70px",
                                            left: "0px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "#F4F4F2",
                                                display: "flex",
                                                justifyContent: "space-around",
                                                padding: "12px 40px",
                                                borderBottomLeftRadius: "16px",
                                                borderBottomRightRadius: "16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "8px 14px",
                                                    borderRadius: "6px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }}
                                                className="shadow-btn"
                                                onClick={(e) => {
                                                    codeSnippetNodeId.current =
                                                        container.nodeId;
                                                    codeSnippetChainId.current =
                                                        container.settings.chainId;
                                                    handleOpenCodeSnippet(e);
                                                }}
                                            >
                                                Verify Contracts
                                            </div>

                                            {container.settings.mnemonic ? (
                                                <div
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            container.settings
                                                                .mnemonic
                                                        );
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor:
                                                            "white",
                                                        borderRadius: "6px",
                                                        fontWeight: "bold",
                                                    }}
                                                    className="shadow-btn"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                rpcUrl
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            backgroundColor:
                                                                "white",
                                                            padding:
                                                                "20px 14px",
                                                            borderRadius: "6px",
                                                            fontWeight: "bold",
                                                            height: "16px",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        Copy Mnemonic
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "#dfe3e6",
                                                        padding: "8px 14px",
                                                        borderRadius: "6px",
                                                        fontWeight: "bold",
                                                        opacity: "0.7",
                                                    }}
                                                >
                                                    Copy Mnemonic
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="node-title-container">
                                        <div syle={{ display: "block" }}>
                                            <h4 style={{ textAlign: "left" }}>
                                                Testnet Name
                                            </h4>
                                            <h3 className="node-title">
                                                <p>
                                                    {" "}
                                                    {replaceStr(containerName)}
                                                </p>
                                                <Chip
                                                    label={
                                                        status === "started"
                                                            ? "Starting"
                                                            : status
                                                    }
                                                    size="small"
                                                    // variant="outlined"
                                                    color={getStatusColor(
                                                        status
                                                    )}
                                                    style={{
                                                        marginLeft: "6px",
                                                        padding: "2px 6px",
                                                    }}
                                                />
                                                {/* <IconButton
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(rpcUrl);
                        }}
                      >
                        <ContentCopyIcon sx={{ fontSize: "15px" }} />
                      </IconButton> */}
                                            </h3>
                                            {container.settings.forking
                                                ?.chainId ? (
                                                <div
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    <h4
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "left",
                                                            margin: "0px 6px",
                                                        }}
                                                    >
                                                        Forked From:
                                                    </h4>
                                                    <h4
                                                        style={{
                                                            fontSize: "22px",
                                                            textAlign: "left",
                                                            margin: "2px 6px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                networkRpc[
                                                                container
                                                                    .settings
                                                                    .forking
                                                                    .chainId
                                                                ][2]
                                                            }
                                                            height="24px"
                                                            style={{
                                                                marginRight:
                                                                    "8px",
                                                            }}
                                                        />

                                                        {(() => {
                                                            if (
                                                                networkRpc[
                                                                container
                                                                    .settings
                                                                    .forking
                                                                    .chainId
                                                                ]
                                                            ) {
                                                                return networkRpc[
                                                                    container
                                                                        .settings
                                                                        .forking
                                                                        .chainId
                                                                ][0];
                                                            } else {
                                                                return (
                                                                    "Chain ID " +
                                                                    container
                                                                        .settings
                                                                        .forking
                                                                        .chainId
                                                                );
                                                            }
                                                        })()}
                                                    </h4>{" "}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div style={{ textAlign: "left" }}>
                                                {container.settings.forking
                                                    ?.blockNumber ? (
                                                    <>
                                                        <h4
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textAlign:
                                                                    "left",
                                                                margin: "0px 6px",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            Block{" "}
                                                            {
                                                                container
                                                                    .settings
                                                                    .forking
                                                                    .blockNumber
                                                            }{" "}
                                                        </h4>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            {/* <ThemeProvider theme={darkButton}>
                        <LoadingButton
                          variant="outlined"
                          style={{ margin: "5px" }}
                          size="small"
                          loading={container.restart.loading}
                          disabled={container.restart.disabled}
                          onClick={() => {
                            restart(container.nodeId);
                          }}
                        >
                          <RestartAltIcon />
                        </LoadingButton>
                      </ThemeProvider> */}

                                            <h4
                                                style={{
                                                    textAlign: "right",
                                                    color: "grey",
                                                }}
                                                className="time-status"
                                            >
                                                CREATED
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                        marginLeft: "5px",
                                                    }}
                                                >
                                                    {createdDuration}
                                                </span>
                                            </h4>
                                            <div style={{ marginLeft: "10px" }}>
                                                {container.settings.chainId ? (
                                                    <>
                                                        <h4
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textAlign:
                                                                    "right",
                                                                margin: "6px 6px",
                                                            }}
                                                        >
                                                            Chain ID:{" "}
                                                            {
                                                                container
                                                                    .settings
                                                                    .chainId
                                                            }{" "}
                                                        </h4>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            {/* Block */}
                                            {/* <div style={{}}>
                                                {container.settings.forking
                                                    ?.blockNumber ? (
                                                    <>
                                                        <h4
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textAlign:
                                                                    "right",
                                                                margin: "0px 6px",
                                                                
                                                            }}
                                                        >
                                                            {" "}
                                                            Block2{" "}
                                                            {
                                                                container
                                                                    .settings
                                                                    .forking
                                                                    .blockNumber
                                                            }{" "}
                                                        </h4>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div> */}

                                            <ThemeProvider theme={darkButton}>
                                                <LoadingButton
                                                    size="small"
                                                    loading={
                                                        container.delete.loading
                                                    }
                                                    disabled={
                                                        container.delete
                                                            .disabled
                                                    }
                                                    onClick={() => {
                                                        deleteNode(
                                                            container.nodeId
                                                        );
                                                    }}
                                                    // style={{ width: "94%" }}
                                                    style={{
                                                        float: "right",
                                                        marginRight: "-10px",
                                                    }}
                                                >
                                                    {/* <DeleteIcon /> */}
                                                    <DarkToolTip
                                                        title={"Delete Node"}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Delete
                                                            width="50px"
                                                            height="40px"
                                                            style={{}}
                                                        />
                                                    </DarkToolTip>
                                                </LoadingButton>
                                            </ThemeProvider>

                                            <br />
                                            <br />

                                            <div
                                                style={{
                                                    width: "100%",
                                                    backgroundColor: "red",
                                                    position: "relative",
                                                }}
                                            >
                                                <ThemeProvider
                                                    theme={darkButton}
                                                    style={{
                                                        maringLeft: "10px",
                                                    }}
                                                >
                                                    <div>
                                                        <LoadingButton
                                                            loading={
                                                                container
                                                                    .metamask
                                                                    .loading
                                                            }
                                                            disabled={
                                                                container
                                                                    .metamask
                                                                    .disabled
                                                            }
                                                            style={{
                                                                marginTop:
                                                                    "20px",
                                                                backgroundColor:
                                                                    "white",
                                                                padding: "8px",
                                                                fontSize:
                                                                    "16px",
                                                                borderRadius:
                                                                    "6px",
                                                                position:
                                                                    "absolute",
                                                                right: "0px",
                                                                width: "220px",
                                                            }}
                                                            size="small"
                                                            onClick={() => {
                                                                connectMetaMask(
                                                                    container.nodeId,
                                                                    rpcUrl,
                                                                    scanUrl
                                                                );
                                                                // console.log(container.nodeId)
                                                            }}
                                                            className="shadow-btn"
                                                        >
                                                            <Metamask
                                                                width="30px"
                                                                height="30px"
                                                            />
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    marginLeft:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {!metamaskLock
                                                                    ? "Add to Metamask"
                                                                    : "Add to Metamask"}
                                                            </span>
                                                        </LoadingButton>
                                                    </div>
                                                </ThemeProvider>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {/* <ThemeProvider
                                                    theme={darkButton}
                                                >
                                                    <LoadingButton
                                                        variant="outlined"
                                                        loading={
                                                            container.metamask
                                                                .loading
                                                        }
                                                        disabled={
                                                            container.metamask
                                                                .disabled
                                                        }
                                                        style={{
                                                            margin: "5px",
                                                        }}
                                                        size="small"
                                                        onClick={() => {
                                                            connectMetaMask(
                                                                container.nodeId,
                                                                rpcUrl,
                                                                scanUrl
                                                            );
                                                            // console.log(container.nodeId)
                                                        }}
                                                    >
                                                        {!metamaskLock
                                                            ? "Unlock and Add to Metamask"
                                                            : "Add to Metamask"}
                                                    </LoadingButton>
                                                </ThemeProvider> */}
                                                {/* <ThemeProvider
                                                    theme={darkButton}
                                                >
                                                    <LoadingButton
                                                        variant="outlined"
                                                        loading={
                                                            container.metamask
                                                                .loading
                                                        }
                                                        disabled={
                                                            container.metamask
                                                                .disabled
                                                        }
                                                        style={{
                                                            margin: "5px",
                                                            position:
                                                                "absolute",
                                                            top: "0px",
                                                            left: "0px",
                                                        }}
                                                        size="small"
                                                        onClick={(e) => {
                                                            artifactUploadingNodeHash.current =
                                                                container.nodeId;
                                                            handleClickOpen(e);
                                                        }}
                                                    >
                                                        Submit Artifacts
                                                    </LoadingButton>
                                                </ThemeProvider> */}
                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    <DialogTitle>
                                                        Upload Artifacts
                                                    </DialogTitle>
                                                    <h4>
                                                        You can upload your
                                                        artefacts folder over
                                                        here. BuildBear will
                                                        programatically parse
                                                        all the files, extract
                                                        the relevant ABIs, match
                                                        them against the
                                                        contracts deployed on
                                                        your private testnet and
                                                        provide you with the
                                                        Read and Write
                                                        Functionality for the
                                                        parsed contracts.
                                                    </h4>
                                                    <DialogContent>
                                                        <div
                                                            style={{
                                                                minWidth:
                                                                    "540px",
                                                            }}
                                                        >
                                                            <DialogContentText></DialogContentText>
                                                            <div>
                                                                <DropFileInput
                                                                    onFileChange={(
                                                                        files
                                                                    ) =>
                                                                        onFileChange(
                                                                            files
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            {isParsing && (
                                                                <div>
                                                                    <LinearWithValueLabel
                                                                        progressPercentage={
                                                                            parseProgress
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            {artifactUploading && (
                                                                <div>
                                                                    <UploadIndicator
                                                                        uploading={
                                                                            artifactUploading
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            {artifactError && (
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        mx: 2,
                                                                    }}
                                                                >
                                                                    {
                                                                        artifactError
                                                                    }
                                                                </Box>
                                                            )}
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                handleClose
                                                            }
                                                            disabled={
                                                                isParsing ||
                                                                artifactUploading
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                
                                                                if (
                                                                    
                                                                    artifactUploading ===
                                                                    "uploaded" ||
                                                                    artifactError
                                                                ) {
                                                                    handleClose();
                                                                } else {
                                                                    
                                                                    
                                                                    handleArtifactSubmit(container.nodeId);
                                                                }
                                                            }}
                                                            disabled={
                                                                isParsing ===
                                                                "parsing" ||
                                                                artifactUploading ===
                                                                "uploading"
                                                            }
                                                        >
                                                            {artifactUploading ===
                                                                "uploaded" ||
                                                                artifactError
                                                                ? "Close"
                                                                : "Submit"}
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Modal
                                                    open={openCodeSnippet}
                                                    onClose={
                                                        handleCloseCodeSnippet
                                                    }
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                    style={{
                                                        backgroundColor: "none",
                                                    }}
                                                >
                                                    <Box className="code-snippet-modal">
                                                        <Box
                                                            sx={{
                                                                borderBottom: 0,
                                                                borderColor:
                                                                    "divider",
                                                            }}
                                                        >
                                                            <Tabs
                                                                value={value}
                                                                // onChange={handleChange}
                                                                textColor="primary"
                                                                indicatorColor="black"
                                                                classes={{
                                                                    indicator:
                                                                        classes.indicator,
                                                                }}
                                                                aria-label="basic tabs example"
                                                                onChange={
                                                                    handleChangeTab
                                                                }
                                                            >
                                                                <Tab
                                                                    label="Verify Contracts using Scripts"
                                                                    {...a11yProps(
                                                                        0
                                                                    )}
                                                                    sx={{
                                                                        fontSize:
                                                                            "18px",
                                                                        textTransform:
                                                                            "none",
                                                                        fontWeight:
                                                                            "bold",
                                                                        padding:
                                                                            "0px 0px",
                                                                        marginRight:
                                                                            "20px",
                                                                    }}
                                                                />
                                                                <Tab
                                                                    label="Upload Artifacts"
                                                                    {...a11yProps(
                                                                        1
                                                                    )}
                                                                    // style={{color: `${
                                                                    //     value ===
                                                                    //     1
                                                                    //         ? "black"
                                                                    //         : "lightgrey"
                                                                    // }  `}}
                                                                    sx={{
                                                                        fontSize:
                                                                            "18px",
                                                                        textTransform:
                                                                            "none",
                                                                        fontWeight:
                                                                            "bold",
                                                                        padding:
                                                                            "0px 0px",
                                                                    }}
                                                                />
                                                            </Tabs>
                                                        </Box>
                                                        <TabPanel
                                                            value={value}
                                                            index={0}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    padding:
                                                                        "0px",
                                                                    margin: "0px",
                                                                    borderColor:
                                                                        "divider",
                                                                }}
                                                            >
                                                                <Tabs
                                                                    value={
                                                                        valueSub
                                                                    }
                                                                    onChange={
                                                                        handleChangeTabSub
                                                                    }
                                                                    aria-label="basic tabs example"
                                                                    indicatorColor="white"
                                                                >
                                                                    <Tab
                                                                        label="Hardhat"
                                                                        {...a11yProps(
                                                                            0
                                                                        )}
                                                                        sx={{
                                                                            borderRadius:
                                                                                "10px",
                                                                            backgroundColor:
                                                                                "white",
                                                                            marginRight:
                                                                                "20px",
                                                                            padding:
                                                                                "3px 30px",
                                                                            textTransform:
                                                                                "none",
                                                                            fontSize:
                                                                                "16px",
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}
                                                                        style={{
                                                                            border: `2px solid ${valueSub ===
                                                                                0
                                                                                ? "black"
                                                                                : "lightgrey"
                                                                                }  `,
                                                                        }}
                                                                        className={
                                                                            valueSub ===
                                                                                0
                                                                                ? "tab-active"
                                                                                : "tab-unactive"
                                                                        }
                                                                    // style={{backgroundColor: valueSub === 0 ? "black" : "green" }}
                                                                    />
                                                                    {/* <Tab
                                                                        label="Truffle"
                                                                        {...a11yProps(
                                                                            1
                                                                        )}
                                                                        sx={{
                                                                            borderRadius:
                                                                                "10px",
                                                                            backgroundColor:
                                                                                "white",
                                                                            marginRight:
                                                                                "20px",
                                                                            padding:
                                                                                "5px 30px",
                                                                            textTransform:
                                                                                "none",
                                                                            fontSize:
                                                                                "16px",
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}
                                                                        style={{
                                                                            border: `2px solid ${
                                                                                valueSub ===
                                                                                1
                                                                                    ? "black"
                                                                                    : "lightgrey"
                                                                            }  `,
                                                                        }}
                                                                        className= { valueSub === 1 ? "tab-active": "tab-unactive" } 
                                                                    /> */}

                                                                    {/* <Tab
                                                                        label="Foundry"
                                                                        {...a11yProps(
                                                                            0
                                                                        )}
                                                                         
                                                                        sx={{
                                                                            borderRadius:
                                                                                "10px",
                                                                            backgroundColor:
                                                                                "#dfe3e6",
                                                                            padding:
                                                                                "5px 30px",
                                                                            textTransform:
                                                                                "none",
                                                                            fontSize:
                                                                                "16px",
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}
                                                                        style={{
                                                                            border: `2px solid ${
                                                                                valueSub ===
                                                                                2
                                                                                    ? "black"
                                                                                    : "lightgrey"
                                                                            }  `,
                                                                        }}
                                                                    /> */}

                                                                    <DarkToolTip
                                                                        title={
                                                                            "Instructions Coming Soon"
                                                                        }
                                                                        arrow
                                                                        placement="top"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                borderRadius:
                                                                                    "10px",
                                                                                backgroundColor:
                                                                                    "#dfe3e6",
                                                                                padding:
                                                                                    "5px 30px",
                                                                                textTransform:
                                                                                    "none",
                                                                                fontSize:
                                                                                    "16px",
                                                                                fontWeight:
                                                                                    "bold",
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                alignItems:
                                                                                    "center",
                                                                                fontFamily:
                                                                                    "arial",
                                                                                border: "2px  solid lightgrey",
                                                                                cursor: "pointer",
                                                                                marginRight:
                                                                                    "20px",
                                                                            }}
                                                                        >
                                                                            Truffle
                                                                        </div>
                                                                    </DarkToolTip>
                                                                    <DarkToolTip
                                                                        title={
                                                                            "Instructions Coming Soon"
                                                                        }
                                                                        arrow
                                                                        placement="top"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                borderRadius:
                                                                                    "10px",
                                                                                backgroundColor:
                                                                                    "#dfe3e6",
                                                                                padding:
                                                                                    "5px 30px",
                                                                                textTransform:
                                                                                    "none",
                                                                                fontSize:
                                                                                    "16px",
                                                                                fontWeight:
                                                                                    "bold",
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                alignItems:
                                                                                    "center",
                                                                                fontFamily:
                                                                                    "arial",
                                                                                border: "2px  solid lightgrey",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            Foundry
                                                                        </div>
                                                                    </DarkToolTip>
                                                                </Tabs>
                                                            </Box>
                                                            <TabPanel
                                                                value={valueSub}
                                                                index={0}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "600px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="sm-y-Scrollbar"
                                                                        style={{
                                                                            overflowY:
                                                                                "scroll",
                                                                            height: "510px",
                                                                            marginBottom:
                                                                                "20px",
                                                                            paddingRight:
                                                                                "10px",
                                                                        }}
                                                                    >
                                                                        <h4
                                                                            style={{
                                                                                marginBottom:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            You
                                                                            can
                                                                            verify
                                                                            your
                                                                            smart
                                                                            contracts
                                                                            using
                                                                            the
                                                                            npm
                                                                            package{" "}
                                                                            <a
                                                                                href="https://www.npmjs.com/package/@nomiclabs/hardhat-etherscan"
                                                                                target="_blank"
                                                                                className="hardhat-link"
                                                                            >
                                                                                hardhat-etherscan
                                                                            </a>
                                                                            .
                                                                            For
                                                                            this
                                                                            purpose,
                                                                            you
                                                                            will
                                                                            need
                                                                            to
                                                                            add
                                                                            the
                                                                            BuildBear
                                                                            Network
                                                                            in
                                                                            your
                                                                            hardhat.config
                                                                            file.
                                                                            Refer
                                                                            this{" "}
                                                                            <a className="hardhat-link" href="https://bit.ly/Contract_Verification_Hardhat" target="_bla" >
                                                                                tutorial
                                                                            </a>
                                                                            .
                                                                            <br />{" "}
                                                                        </h4>
                                                                        <h4>
                                                                            {" "}
                                                                            Following
                                                                            are
                                                                            the
                                                                            code
                                                                            snippets
                                                                            that
                                                                            you
                                                                            can
                                                                            use:
                                                                        </h4>

                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                alignItems:
                                                                                    "center",
                                                                                marginBottom:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                (1)
                                                                                Adding
                                                                                BuildBear
                                                                                as
                                                                                a
                                                                                Network
                                                                            </h4>
                                                                            <Typography
                                                                                id="modal-modal-title"
                                                                                variant="h6"
                                                                                component="h2"
                                                                            >
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "white",
                                                                                        borderRadius:
                                                                                            "10px",
                                                                                        padding:
                                                                                            "2px 4px",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        navigator.clipboard.writeText(
                                                                                            codeRef3
                                                                                                .current
                                                                                                .innerText
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ContentCopyIcon
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "20px",
                                                                                            margin: "10px",
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Typography>
                                                                        </div>

                                                                        <pre
                                                                            className="hljs"
                                                                            style={{
                                                                                marginBottom:
                                                                                    "20px",
                                                                            }}
                                                                        >
                                                                            <code
                                                                                className="language-javascript"
                                                                                ref={
                                                                                    codeRef3
                                                                                }
                                                                            >
                                                                                {`buildbear: {
      url: "https://rpc.${process.env.REACT_APP_BASE_URL}/${codeSnippetNodeId.current}"
    }`}
                                                                            </code>
                                                                        </pre>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                alignItems:
                                                                                    "center",
                                                                                marginBottom:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                (2)
                                                                                Adding
                                                                                custom
                                                                                chain
                                                                                in
                                                                                the
                                                                                etherscan
                                                                                object
                                                                                (as
                                                                                required
                                                                                by
                                                                                hardhat-etherscan
                                                                                package)
                                                                            </h4>
                                                                            <Typography
                                                                                id="modal-modal-title"
                                                                                variant="h6"
                                                                                component="h2"
                                                                            >
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "white",
                                                                                        borderRadius:
                                                                                            "10px",
                                                                                        padding:
                                                                                            "2px 4px",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        navigator.clipboard.writeText(
                                                                                            codeRef2
                                                                                                .current
                                                                                                .innerText
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ContentCopyIcon
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "20px",
                                                                                            margin: "10px",
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Typography>
                                                                        </div>

                                                                        <pre
                                                                            className="hljs"
                                                                            style={{
                                                                                marginBottom:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            <code
                                                                                className="language-javascript"
                                                                                ref={
                                                                                    codeRef2
                                                                                }
                                                                            >
                                                                                {`etherscan: {
    apiKey: {
      buildbear: "verifyContract",
    },
    customChains: [
      {
        network: "buildbear",
        chainId: ${codeSnippetChainId.current},
        urls: {
          apiURL: "https://rpc.${process.env.REACT_APP_BASE_URL}/verify/etherscan/${codeSnippetNodeId.current}",
          browserURL: "https://explorer.${process.env.REACT_APP_BASE_URL}/${codeSnippetNodeId.current}",
        },
      },
    ],
  }`}
                                                                            </code>
                                                                        </pre>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                backgroundColor:
                                                                                    "white",
                                                                                padding:
                                                                                    "8px 14px",
                                                                                borderRadius:
                                                                                    "6px",
                                                                                fontWeight:
                                                                                    "bold",
                                                                                fontSize:
                                                                                    "18px",
                                                                                border: "2px solid black",
                                                                                borderRadius:
                                                                                    "10px",
                                                                                marginLeft:
                                                                                    "16px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                handleCloseCodeSnippet();
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel
                                                                value={valueSub}
                                                                index={1}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "600px",
                                                                    }}
                                                                >
                                                                    <pre
                                                                        className="hljs"
                                                                        style={{
                                                                            marginBottom:
                                                                                "16px",
                                                                        }}
                                                                    >
                                                                        <code
                                                                            className="language-javascript"
                                                                            ref={
                                                                                codeRef
                                                                            }
                                                                        >
                                                                            {`{
  networks: {
    buildbear: {
      url: https://rpc.buildbear.io/${codeSnippetNodeId.current}
    }
  },
  etherscan: {
    apiKey: {
      buildbear: "test1",
    },
    customChains: [
      {
        network: "buildbear",
        chainId: ${codeSnippetChainId.current},
        urls: {
          apiURL: https://rpc.dev.buildbear.io/verify/etherscan/${codeSnippetNodeId.current},
          browserURL: https://explorer.dev.buildbear.io/${codeSnippetNodeId.current},
        },
      },
    ],
  }
}`}
                                                                        </code>
                                                                    </pre>
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            id="modal-modal-title"
                                                                            variant="h6"
                                                                            component="h2"
                                                                        >
                                                                            <IconButton
                                                                                color="primary"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        "white",
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                    padding:
                                                                                        "4px 14px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        codeRef
                                                                                            .current
                                                                                            .innerText
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ContentCopyIcon
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            "20px",
                                                                                        margin: "10px 10px 10px 0px",
                                                                                    }}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "18px",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    Copy
                                                                                    Code
                                                                                </span>
                                                                            </IconButton>
                                                                        </Typography>

                                                                        <div
                                                                            style={{
                                                                                backgroundColor:
                                                                                    "white",
                                                                                padding:
                                                                                    "8px 14px",
                                                                                borderRadius:
                                                                                    "6px",
                                                                                fontWeight:
                                                                                    "bold",
                                                                                fontSize:
                                                                                    "18px",
                                                                                border: "2px solid black",
                                                                                borderRadius:
                                                                                    "10px",
                                                                                marginLeft:
                                                                                    "16px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                handleCloseCodeSnippet();
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            {/* <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    gap: "1em",
                                                                }}
                                                            >
                                                                <Button
                                                                    sx={{
                                                                        textTransform:
                                                                            "none",
                                                                        border: "2px solid #e2edee",
                                                                        borderRadius:
                                                                            "10px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        padding:
                                                                            "6px 16px",
                                                                    }}
                                                                    size="medium"
                                                                    className="CustomButton"
                                                                >
                                                                    Hardhat
                                                                </Button>
                                                                <Button
                                                                    sx={{
                                                                        textTransform:
                                                                            "none",
                                                                        border: "2px solid #e2edee",
                                                                        borderRadius:
                                                                            "10px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        padding:
                                                                            "6px 16px",
                                                                    }}
                                                                    size="medium"
                                                                    className="CustomButton"
                                                                >
                                                                    Truffle
                                                                </Button>
                                                                <Button
                                                                    sx={{
                                                                        textTransform:
                                                                            "none",
                                                                        border: "2px solid #e2edee",
                                                                        borderRadius:
                                                                            "10px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        padding:
                                                                            "6px 16px",
                                                                    }}
                                                                    size="medium"
                                                                    className="CustomButton"
                                                                >
                                                                    Foundry
                                                                </Button>
                                                            </div> */}
                                                        </TabPanel>
                                                        <TabPanel
                                                            value={value}
                                                            index={1}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: "600px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        minWidth:
                                                                            "500px",
                                                                    }}
                                                                >
                                                                    <h4>
                                                                        You can
                                                                        upload
                                                                        your
                                                                        artifacts
                                                                        folder
                                                                        over
                                                                        here.
                                                                        BuildBear
                                                                        will
                                                                        programatically
                                                                        provide
                                                                        you with
                                                                        the Read
                                                                        and
                                                                        Write
                                                                        Functionalities
                                                                        for the
                                                                        verified
                                                                        contracts.
                                                                    </h4>
                                                                    <DialogContentText></DialogContentText>
                                                                    <div>
                                                                        <DropFileInput
                                                                            onFileChange={(
                                                                                files
                                                                            ) =>
                                                                                onFileChange(
                                                                                    files
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {isParsing && (
                                                                        <div>
                                                                            <LinearWithValueLabel
                                                                                progressPercentage={
                                                                                    parseProgress
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {artifactUploading && (
                                                                        <div>
                                                                            <UploadIndicator
                                                                                uploading={
                                                                                    artifactUploading
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {artifactError && (
                                                                        <Box
                                                                            sx={{
                                                                                width: "100%",
                                                                                mx: 2,
                                                                            }}
                                                                        >
                                                                            {
                                                                                artifactError
                                                                            }
                                                                        </Box>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "right",
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{
                                                                        backgroundColor:
                                                                            "white",
                                                                        fontSize:
                                                                            "20px",
                                                                        textTransform:
                                                                            "none",
                                                                        borderRadius:
                                                                            "10px",
                                                                        padding:
                                                                            "5px 20px",
                                                                        border: "2px solid black",
                                                                    }}
                                                                    onClick={() => {
                                                                        
                                                                        if (
                                                                            artifactUploading ===
                                                                            "uploaded" ||
                                                                            artifactError
                                                                        ) {
                                                                            // handleClose();
                                                                            handleCloseCodeSnippet();
                                                                        } else {
                                                                            
                                                                            handleArtifactSubmit(container.nodeId);
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        isParsing ===
                                                                        "parsing" ||
                                                                        artifactUploading ===
                                                                        "uploading"
                                                                    }
                                                                >
                                                                    {artifactUploading ===
                                                                        "uploaded" ||
                                                                        artifactError
                                                                        ? "Close"
                                                                        : "Submit"}
                                                                </Button>
                                                                {/* <div
                                                                    style={{
                                                                        backgroundColor:
                                                                            "white",
                                                                        padding:
                                                                            "8px 14px",
                                                                        borderRadius:
                                                                            "6px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            "18px",
                                                                        border: "2px solid black",
                                                                        borderRadius:
                                                                            "10px",
                                                                        marginLeft:
                                                                            "16px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        handleCloseCodeSnippet();
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </div> */}
                                                            </div>
                                                        </TabPanel>
                                                    </Box>
                                                </Modal>
                                                {/* <Modal
                                                    open={openCodeSnippet}
                                                    onClose={
                                                        handleCloseCodeSnippet
                                                    }
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                  
                                                >
                                                    <Box className="code-snippet-modal">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <Typography
                                                                    id="modal-modal-title"
                                                                    variant="h6"
                                                                    component="h2"
                                                                >
                                                                    Code Snippet
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            navigator.clipboard.writeText(
                                                                                codeRef
                                                                                    .current
                                                                                    .innerText
                                                                            );
                                                                        }}
                                                                    >
                                                                        <ContentCopyIcon
                                                                            sx={{
                                                                                fontSize:
                                                                                    "15px",
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Typography>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        handleCloseCodeSnippet();
                                                                    }}
                                                                >
                                                                    <CancelIcon
                                                                        sx={{
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                        <pre className="hljs">
                                                            <code
                                                                className="language-javascript"
                                                                ref={codeRef}
                                                            >
                                                                {`{
  networks: {
    buildbear: {
      url: https://rpc.buildbear.io/${codeSnippetNodeId.current}
    }
  },
  etherscan: {
    apiKey: {
      buildbear: "test1",
    },
    customChains: [
      {
        network: "buildbear",
        chainId: ${codeSnippetChainId.current},
        urls: {
          apiURL: https://rpc.dev.buildbear.io/verify/etherscan/${codeSnippetNodeId.current},
          browserURL: https://explorer.dev.buildbear.io/${codeSnippetNodeId.current},
        },
      },
    ],
  }
}`}
                                                            </code>
                                                        </pre>
                                                    </Box>
                                                </Modal> */}
                                            </div>
                                        </div>
                                    </div>

                                    <hr
                                        style={{
                                            color: "green",
                                            marginBottom: "14px",
                                            borderTop: "2px solid  #c8c8c8",
                                        }}
                                    />
                                    {/* <div> Get RPC URL <IconButton
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(rpcUrl);
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: "15px" }} />
                  </IconButton></div> */}
                                    {status === "Initializing" && (
                                        <div className="wrapper">
                                            <CircularProgress
                                                sx={{ color: "#000" }}
                                            />
                                        </div>
                                    )}
                                    {(status === "Error" ||
                                        status === "Deleted" ||
                                        status === "Killed") && (
                                            <>
                                                <div
                                                    style={{
                                                        minHeight: "200px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography variant="h5">
                                                        Node is not live{" "}
                                                    </Typography>
                                                </div>
                                            </>
                                        )}
                                    {status === "Deleted due to inactivity" && (
                                        <>
                                            <div
                                                style={{
                                                    minHeight: "200px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    This node was deleted as it
                                                    was inactive for 4 days{" "}
                                                </Typography>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        style={{
                                            display: "",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "8px 14px",
                                                borderRadius: "6px",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                let temp = containers;
                                                // console.log(temp.find(nodeId => nodeId))
                                                // temp.find(nodeId => nodeId).showRpc = true
                                                temp[index].showRpc =
                                                    !temp[index].showRpc;
                                                setContainers([...temp]);

                                                if(temp[index].showRpc === true){
                                                    setTimeout(()=>{
                                                        temp[index].showRpc = false;
                                                        setContainers([...temp]);

                                                    },[10000])
                                                }
                                              
                                                // console.log(container.showRpc)
                                            }}
                                            className="shadow-btn"
                                        >
                                            View RPC
                                            {/* {container.showRpc ? (
                                                        <div
                                                            style={{
                                                                textTransform:
                                                                    "none",
                                                                fontSize:
                                                                    "15px",
                                                                overflow:
                                                                    "scroll",
                                                                margin: "6px",
                                                                height: "45px",
                                                            }}
                                                        >
                                                            {rpcUrl}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )} */}
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "8px 0px",
                                                borderRadius: "6px",
                                                fontWeight: "bold",
                                            }}
                                            className="shadow-btn"
                                        >
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={scanUrl + "/transactions"}
                                                style={{ width: "100%", padding: "10px 20px" }}
                                            >
                                                <a onClick={explorerEvent}>
                                                    Open Explorer
                                                </a>
                                            </a>{" "}
                                        </div>
                                        {status !== "stopped" ? (
                                            <div
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "8px 0px",
                                                    borderRadius: "6px",
                                                    fontWeight: "bold",
                                                }}
                                                className="shadow-btn"
                                            >
                                                <div
                                                    style={
                                                        {
                                                            // textTransform: "uppercase",
                                                        }
                                                    }
                                                >
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={faucetUrl}
                                                        style={{ width: "100%", padding: "10px 20px" }}

                                                    >
                                                        {/* <Faucet
                                                            width="30px"
                                                            height="16px"
                                                        /> */}

                                                        <a
                                                            onClick={
                                                                faucetEvent
                                                            }
                                                        >
                                                            Open Faucet
                                                        </a>
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    backgroundColor: "#dfe3e6",
                                                    padding: "8px 14px",
                                                    borderRadius: "6px",
                                                    fontWeight: "bold",
                                                    opacity: "0.7",
                                                }}
                                            >
                                                <div
                                                    style={
                                                        {
                                                            // textTransform: "uppercase",
                                                        }
                                                    }
                                                >
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={
                                                            {
                                                                // margin: "6px",
                                                            }
                                                        }
                                                    >
                                                        {/* <Faucet
                                                            width="30px"
                                                            height="16px"
                                                        /> */}

                                                        <a
                                                            onClick={
                                                                faucetEvent
                                                            }
                                                        >
                                                            Open Faucet
                                                        </a>
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* {showRcp ? (
                                        ""
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    backgroundColor: "white",
                                                    height: "50px",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    display: "flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        textTransform: "none",
                                                        fontSize: "15px",
                                                        overflow: "scroll",
                                                        margin: "6px",
                                                        height: "45px",
                                                    overflowY: "hidden"

                                                    }}
                                                    className=" sm-Scrollbar"
                                                >
                                                    {rpcUrl}
                                                </div>
                                                <div>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                rpcUrl
                                                            );
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            sx={{
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </>
                                    )} */}
                                    {container.showRpc ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                paddingTop: "10px"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textTransform: "none",
                                                    fontSize: "16px",
                                                    overflowY: "hidden",
                                                    margin: "6px",
                                                    height: "50px",
                                                    paddingTop: "10px",
                                                }}
                                            // className="sm-Scrollbar"
                                            >
                                                {rpcUrl}
                                            </div>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        rpcUrl
                                                    );
                                                }}
                                            >
                                                <ContentCopyIcon
                                                    sx={{
                                                        fontSize: "15px",
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* NewFooter */}
                                    <div className="">
                                        {/* <div
                                        style={{
                                            backgroundColor: "green",
                                            display: "flex",
                                            justifyContent: "space-around",
                                            padding: "10px 40px",
                                            borderBottomLeftRadius: "20px",
                                            borderBottomRightRadius: "20px",
                                            borderTop: "10px solid red",
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "8px 14px",
                                                borderRadius: "6px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Verify Contracts
                                        </div>

                                        {container.settings.mnemonic ? (
                                            <button
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        container.settings
                                                            .mnemonic
                                                    );
                                                }}
                                                style={{
                                                    textTransform: "uppercase",

                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                    padding: "8px 14px",
                                                    borderRadius: "6px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Copy Mnemonic
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div> */}
                                    </div>

                                    {/* <div style={{display: "flex"}} >
                                        {status !== "stopped" ? (
                                            <div>
                                                <div
                                                    style={{
                                                        fontSize: "20px",
                                                                                                                display: "block"
                                                    }}
                                                >
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={faucetUrl}
                                                        style={{
                                                            // margin: "6px",
                                                            // backgroundColor:
                                                            //     "white",
                                                            // padding: "10px",
                                                            // borderRadius:
                                                            //     "10px",
                                                            // fontWeight: "bold",
                                                            fontSize: "1px"
                                                        }}
                                                    >
                                                        <a
                                                            onClick={
                                                                faucetEvent
                                                            }

                                                        >
                                                            1Open Faucet
                                                        </a>
                                                    </a>{" "}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "20px",
                                                textAlign: "left",
                                            }}
                                        >
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={scanUrl + "/transactions"}
                                                style={{
                                                    margin: "6px",
                                                    backgroundColor: "white",
                                                    padding: "10px",
                                                    borderRadius: "10px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <a onClick={explorerEvent}>
                                                    1Open explorer
                                                </a>
                                            </a>
                                        </div>
                                        <div>
                                            <h3
                                                style={{
                                                    textAlign: "left",
                                                    padding: "10px",
                                                    borderRadius: "10px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "white",
                                                    display: "inlineBlock",
                                                }}
                                            >
                                                1Testnet URL:{" "}
                                            </h3>
                                            <div
                                                style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "20px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        border: "none",
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: "20px",
                                                        backgroundColor: "red",
                                                        padding: "0px",
                                                        margin: "6px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        let temp = containers;
                                                        // console.log(temp.find(nodeId => nodeId))
                                                        // temp.find(nodeId => nodeId).showRpc = true
                                                        temp[index].showRpc =
                                                            !temp[index]
                                                                .showRpc;
                                                        setContainers([
                                                            ...temp,
                                                        ]);
                                                        // console.log(container.showRpc)
                                                    }}
                                                >
                                                    Click to view
                                                </button>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            rpcUrl
                                                        );
                                                    }}
                                                >
                                                    <ContentCopyIcon
                                                        sx={{
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </IconButton>
                                                {container.showRpc ? (
                                                    <div
                                                        style={{
                                                            textTransform:
                                                                "none",
                                                            fontSize: "15px",
                                                            overflow: "scroll",
                                                            margin: "6px",
                                                            height: "45px",
                                                        }}
                                                    >
                                                        {rpcUrl}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div> */}

                                    {status !== "Error" &&
                                        status !== "Deleted" &&
                                        status !== "Killed" &&
                                        status !==
                                        "Deleted due to inactivity" &&
                                        status !== "Initializing" && (
                                            <>
                                                {/* <h4
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Testnet URL:{" "}
                                                </h4>
                                                <div
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: "20px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            textTransform:
                                                                "uppercase",
                                                            fontSize: "20px",
                                                            backgroundColor:
                                                                "transparent",
                                                            padding: "0px",
                                                            margin: "6px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            let temp =
                                                                containers;
                                                            // console.log(temp.find(nodeId => nodeId))
                                                            // temp.find(nodeId => nodeId).showRpc = true
                                                            temp[
                                                                index
                                                            ].showRpc =
                                                                !temp[index]
                                                                    .showRpc;
                                                            setContainers([
                                                                ...temp,
                                                            ]);
                                                            // console.log(container.showRpc)
                                                        }}
                                                    >
                                                        Click to view
                                                    </button>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                rpcUrl
                                                            );
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            sx={{
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        />
                                                    </IconButton>
                                                    {container.showRpc ? (
                                                        <div
                                                            style={{
                                                                textTransform:
                                                                    "none",
                                                                fontSize:
                                                                    "15px",
                                                                overflow:
                                                                    "scroll",
                                                                margin: "6px",
                                                                height: "45px",
                                                            }}
                                                        >
                                                            {rpcUrl}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div> */}
                                                {/* <h4
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Blockchain Explorer URL:{" "}
                                                </h4>
                                                <div
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: "20px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={
                                                            scanUrl +
                                                            "/transactions"
                                                        }
                                                        style={{
                                                            margin: "6px",
                                                        }}
                                                    >
                                                        <a
                                                            onClick={
                                                                explorerEvent
                                                            }
                                                        >
                                                            Open explorer
                                                        </a>
                                                    </a>{" "}
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                scanUrl
                                                            );
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            sx={{
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div> */}
                                                {/* {status !== "stopped" ? (
                                                    <>
                                                        <h4
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Faucet URL:{" "}
                                                        </h4>
                                                        <div
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "20px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={faucetUrl}
                                                                style={{
                                                                    margin: "6px",
                                                                }}
                                                            >
                                                                <a
                                                                    onClick={
                                                                        faucetEvent
                                                                    }
                                                                >
                                                                    Open Faucet
                                                                </a>
                                                            </a>{" "}
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                        faucetUrl
                                                                    );
                                                                }}
                                                            >
                                                                <ContentCopyIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )} */}
                                                {/* 
                                                {container.settings.mnemonic ? (
                                                    <>
                                                        <h4
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Unlocked Accounts:{" "}
                                                        </h4>
                                                        <div
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "20px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            <button
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                        container
                                                                            .settings
                                                                            .mnemonic
                                                                    );
                                                                }}
                                                                style={{
                                                                    border: "none",
                                                                    textTransform:
                                                                        "uppercase",
                                                                    fontSize:
                                                                        "20px",
                                                                    backgroundColor:
                                                                        "transparent",
                                                                    padding:
                                                                        "0px",
                                                                    margin: "6px",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Copy Mnemonic
                                                            </button>{" "}
                                                           
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h4
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Unlocked Accounts:{" "}
                                                        </h4>
                                                        <div
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "20px",
                                                                textAlign:
                                                                    "left",
                                                                margin: "6px",
                                                            }}
                                                        >
                                                            NO UNLOCKED ACCOUNTS
                                                            CREATED
                                                        </div>
                                                    </>
                                                )} */}
                                                {/* <h4
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Created:{" "}
                                                </h4>
                                                <div
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: "20px",
                                                        margin: "6px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {createdDuration}
                                                </div> */}
                                                {/* <h4>Uptime since restart: </h4>
                        <div
                          style={{
                            textTransform: "uppercase",
                            fontSize: "20px",
                            margin:'6px',
                          }}
                        >
                          {uptime}
                        </div> */}
                                            </>
                                        )}
                                    {/* <div>
                    <div>Restart your Node</div>
                    <LoadingButton
                      variant="outlined"
                      style={{ color: '#000' }}
                      size="small"
                      loading={container.restart.loading}
                      disabled={container.restart.disabled}
                      onClick={() => {
                        restart(container.hash);
                      }}
                    >

                      <RestartAltIcon />
                    </LoadingButton>
                  </div> */}
                                </div>
                            </>
                        );
                    })}
                </div>
            </>
        );
    }
}

export default ContainerList;
