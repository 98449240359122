import Nav from "../components/Nav"
import { AuthContext } from "../bb-app/App";
import { Link, Navigate } from "react-router-dom";
import React, { useContext, useRef, useState, useEffect } from "react";
import AppContainerList from "../components/AppContainerList";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Styled from "styled-components";

import { getAllUserContainers } from "../api/home";
import { styled } from '@mui/material/styles';

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255,255,255,0.87) !important',
    fontSize: 13,
  },
}));

const AppDashboad = () => {

  const { state, dispatch } = useContext(AuthContext);
  const freshContainers = useRef();
  const [loading, setLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  // const [showRpc, setShowRpc] = useState()
  const [mounted, setMounted] = useState(true);
  const [bool, setBool] = useState(false);

  useEffect(() => {
    async function getContainers() {
      const response = await getAllUserContainers();
      if (response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        // useNavigate.replace("/login");
        // history("/login", { replace: true })
      } else if (response.err) {
        setContainers([]);
        freshContainers.current = [];
      }
      setLoading(false);
    }
    if (mounted) {
      getContainers();
    }

    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dispatch]);

  // console.log("state", state);

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };




  return (
    <Wrapper>
    <>
      <Nav />
      {/* <div className="container">
        <Navbar />
      </div> */}
      <div className="dash-wrapper">
        <div className="dash-sidebar">
          <ul>
        
            <div className="sidebar-list-bottom">
      
        
              {state.isAdmin && (
                <Link className="nav-btn" to="/admin">
                  <li><b>
                    Admin
                  </b>
                  </li>
                </Link>
              )}
              <li onClick={() => handleLogout()}>
                <b>Sign Out</b>
              </li>
              {/* <li><b>Sign Out</b></li> */}
            </div>
          </ul>
        </div>
        <div className="dashboardBody">
          <Box className="dashBoardBox">
            <Box className="dashHeader">
              {/* <Typography variant="h5">
                {" "}
                Containers{" "}
              </Typography> */}
              {/* <Link to="/create" className="createButton">
                <Typography variant="button" sx={{ fontSize: "13px" }}>
                  {" "}
                  + Create a Node{" "}
                </Typography>
              </Link> */}
            </Box>
            <AppContainerList />
          </Box>
        </div>
      </div>
    </>
  </Wrapper>

  )
}

const Wrapper = Styled.section`
.container{
  display: flex;
  flex-direction: column;
  height: 7vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: Arial;
  .container{
    display: flex;
    flex-direction: column;
    height: 60px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: Arial;
  
    button{
      all: unset;
      width: 100px;
      height: 35px;
      margin: 10px;
      background-color: #0F4492;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      transition: 0.06s ease-in;
  
      &:hover{
        background-color: #fff;
        color: #0041C2;
      }
    }
  }
  button{
    all: unset;
    width: 100px;
    height: 35px;
    margin: 10px;
    align-self: flex-end;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #bfbfbf;
    transition: 0.06s ease-in;

    
  }

  >div{
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;

    .content{
      display: flex;
      flex-direction: column;
      padding: 20px 100px;    
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      width: auto;
  
      img{
        height: 150px;
        width: 150px;
        border-radius: 50%;
      }
  
      >span:nth-child(2){
        margin-top: 20px;
        font-weight: bold;
      }
  
      >span:not(:nth-child(2)){
        margin-top: 8px;
        font-size: 14px;
      }
  
    }

  }
}
.endpoint-btn {
  margin-top: 21%;
  margin-left: 45%;
  padding: 15px 20px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  background-color: transparent;
  transition: 0.2s ease-in-out;
 span {
   color: #fff;
   text-decoration: none;
 }
}


`;

export default AppDashboad;