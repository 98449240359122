import React, { createContext, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import AppDashboard from "../pages/AppDashboard";
import AppLogin from "../pages/AppLogin";
import BBappGithubLogin from "../pages/BBappGitHubLogin";
import PageNotFound from "../pages/404";
import { initialState, reducer } from "../store/reducer/bbApp";
import "./App.css";
import { Helmet } from "react-helmet";

export const AuthContext = createContext();

function BuildBearApp() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Helmet>
        <title>App || BuildBear Testnet</title>
      </Helmet>
      <Routes>
        <Route path="/*" element={<AppDashboard />} />
        <Route path="/login" element={<AppLogin />} />
        <Route path="/login/github" element={<BBappGithubLogin />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default BuildBearApp;