import { LandingRouter, ExplorerRouter, FaucetRouter, HomeRouter, AppRouter } from "../routers";

export const APPS = [
  {
    subdomain: "",
    app: LandingRouter,
    main: true,
  },
  {
    subdomain: "home",
    app: HomeRouter,
    main: false,
  },
  {
    subdomain2: "dev",
    subdomain: "explorer",
    app: ExplorerRouter,
    main: false,
  },
  {
    subdomain: "faucet",
    app: FaucetRouter,
    main: false,
  },
  {
    subdomain: "demo",
    app: AppRouter,
    main: false,
  },
]
