module.exports = {
    "1": {
        "USDC": {
            "name": "USD Coin",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            "slots": {
                "balance": "0x9",
                "totalSupply": "0xb"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            "slots": {
                "balance": "0x2",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "USDT": {
            "name": "Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            "slots": {
                "balance": "0x2",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "BNB": {
            "name": "BNB",
            "symbol": "BNB",
            "decimals": 18,
            "address": "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
            "slots": {
                "balance": "0x5",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png"
        },
        "BUSD": {
            "name": "Binance USD",
            "symbol": "BUSD",
            "decimals": 18,
            "address": "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png"
        },
        "MATIC": {
            "name": "Matic Token",
            "symbol": "MATIC",
            "decimals": 18,
            "address": "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png"
        },
        "WBTC": {
            "name": "Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "UNI": {
            "name": "Uniswap",
            "symbol": "UNI",
            "decimals": 18,
            "address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
            "slots": {
                "balance": "0x4",
                "totalSupply": "0x0"
            },
            "image": "https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png"
        },
        "AAVE": {
            "name": "Aave Token",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        }
    },
    "56": {
        "USDC": {
            "name": "USD Coin",
            "symbol": "USDC",
            "decimals": 18,
            "address": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "Dai Token",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "BUSD": {
            "name": "BUSD Token",
            "symbol": "BUSD",
            "decimals": 18,
            "address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png"
        },
        "MATIC": {
            "name": "Matic Token",
            "symbol": "MATIC",
            "decimals": 18,
            "address": "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png"
        },
        "AAVE": {
            "name": "Aave Token",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0xfb6115445Bff7b52FeB98650C87f44907E58f802",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        }
    },
    "137": {
        "USDC": {
            "name": "USD Coin (PoS)",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "(PoS) Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "USDT": {
            "name": "(PoS) Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "WBTC": {
            "name": "(PoS) Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "UNI": {
            "name": "Uniswap (PoS)",
            "symbol": "UNI",
            "decimals": 18,
            "address": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png"
        },
        "AAVE": {
            "name": "Aave (PoS)",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        }
    },
    "10": {
        "USDC": {
            "name": "USD Coin (PoS)",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "(PoS) Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "USDT": {
            "name": "(PoS) Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "WBTC": {
            "name": "(PoS) Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "LINK": {
            "name": "Chainlink (PoS)",
            "symbol": "LINK",
            "decimals": 18,
            "address": "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png"
        },
        "AAVE": {
            "name": "Aave (PoS)",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0x76FB31fb4af56892A25e32cFC43De717950c9278",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        }
    },
    "42161": {
        "USDC": {
            "name": "USD Coin (PoS)",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "(PoS) Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "USDT": {
            "name": "(PoS) Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "WBTC": {
            "name": "(PoS) Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "LINK": {
            "name": "Chainlink (PoS)",
            "symbol": "LINK",
            "decimals": 18,
            "address": "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png"
        },
        "AAVE": {
            "name": "Aave (PoS)",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        }
    },
    "250": {
        "USDC": {
            "name": "USD Coin (PoS)",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "DAI": {
            "name": "(PoS) Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "USDT": {
            "name": "(PoS) Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "WBTC": {
            "name": "(PoS) Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x321162Cd933E2Be498Cd2267a90534A804051b11",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "LINK": {
            "name": "Chainlink (PoS)",
            "symbol": "LINK",
            "decimals": 18,
            "address": "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png"
        },
        "AAVE": {
            "name": "Aave (PoS)",
            "symbol": "AAVE",
            "decimals": 18,
            "address": "0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png"
        },
        "WETH": {
            "name": "Wrapped Ether (PoS)",
            "symbol": "WETH",
            "decimals": 18,
            "address": "0x74b23882a30290451A17c44f4F05243b6b58C76d",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/29102/large/wrapped-ether-xdai.png"
        }
    },
    "43114": {
        "USDT.e": {
            "name": "Tether (Plenty Bridge)",
            "symbol": "USDT.e",
            "decimals": 6,
            "address": "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "USDT": {
            "name": "(PoS) Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "USDC": {
            "name": "USD Coin (PoS)",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "USDC.e": {
            "name": "USD Coin Avalanche Bridged",
            "symbol": "USDC.e",
            "decimals": 6,
            "address": "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "BUSD.e": {
            "name": "Binance USD Avalanche Bridged",
            "symbol": "BUSD.e",
            "decimals": 18,
            "address": "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png"
        },
        "BUSD": {
            "name": "Binance USD",
            "symbol": "BUSD",
            "decimals": 18,
            "address": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png"
        },
        "WAVAX": {
            "name": "Wrapped AVAX",
            "symbol": "WAVAX",
            "decimals": 18,
            "address": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/15075/large/wrapped-avax.png"
        },
        "DAI.e": {
            "name": "Dai Stablecoin Avalanche Bridged",
            "symbol": "DAI.e",
            "decimals": 18,
            "address": "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
            "slots": {
                "balance": "0x2",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "WBTC.e": {
            "name": "WBTC Avalanche Bridged",
            "symbol": "WBTC.e",
            "decimals": 8,
            "address": "0x50b7545627a5162F82A992c33b87aDc75187B218",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "UNI.e": {
            "name": "Uniswap Avalanche Bridged",
            "symbol": "UNI.e",
            "decimals": 18,
            "address": "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580",
            "slots": {
                "balance": "0x4",
                "totalSupply": "0x0"
            },
            "image": "https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png"
        },
        "TUSD": {
            "name": "TrueUSD",
            "symbol": "TUSD",
            "decimals": 18,
            "address": "0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB",
            "slots": {
                "balance": "0x4",
                "totalSupply": "0x0"
            },
            "image": "https://assets.coingecko.com/coins/images/3449/large/tusd.png"
        },
        "WETH.e": {
            "name": "WETH Avalanche Bridged",
            "symbol": "WETH.e",
            "decimals": 18,
            "address": "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/29102/large/wrapped-ether-xdai.png"
        }
    },
    "2222": {
        "USDC": {
            "name": "USD Coin",
            "symbol": "USDC",
            "decimals": 6,
            "address": "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
            "slots": {
                "balance": "0x9",
                "totalSupply": "0xb"
            },
            "image": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png"
        },
        "WKAVA": {
            "name": "Wrapped Kava",
            "symbol": "WKAVA",
            "decimals": 6,
            "address": "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
            "slots": {
                "balance": "0x9",
                "totalSupply": "0xb"
            },
            "image": "https://assets.coingecko.com/coins/images/9761/large/kava.png"
        },
        "USDT": {
            "name": "Tether USD",
            "symbol": "USDT",
            "decimals": 6,
            "address": "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
            "slots": {
                "balance": "0x2",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
        },
        "ETH": {
            "name": "Ethereum ",
            "symbol": "ETH",
            "decimals": 6,
            "address": "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
            "slots": {
                "balance": "0x2",
                "totalSupply": "0x1"
            },
            "image": "https://assets.coingecko.com/coins/images/279/large/ethereum.png"
        },
        "WBTC": {
            "name": "(PoS) Wrapped BTC",
            "symbol": "WBTC",
            "decimals": 8,
            "address": "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png"
        },
        "DAI": {
            "name": "(PoS) Dai Stablecoin",
            "symbol": "DAI",
            "decimals": 18,
            "address": "0x765277eebeca2e31912c9946eae1021199b39c61",
            "slots": {
                "balance": "0x0",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png"
        },
        "BUSD": {
            "name": "Binance USD",
            "symbol": "BUSD",
            "decimals": 18,
            "address": "0x332730a4F6E03D9C55829435f10360E13cfA41Ff",
            "slots": {
                "balance": "0x1",
                "totalSupply": "0x2"
            },
            "image": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png"
        },
        "BNB": {
            "name": "BNB",
            "symbol": "BNB",
            "decimals": 18,
            "address": "0xabd380327fe66724ffda91a87c772fb8d00be488",
            "slots": {
                "balance": "0x5",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png"
        },
        "maWKAVA": {
            "name": "Mare Wrapped KAVA",
            "symbol": "maWKAVA",
            "decimals": 18,
            "address": "0x24149e2D0D3F79EBb7Fc464b09e3628dE395b39D",
            "slots": {
                "balance": "0x5",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/9761/large/kava.png"

        },
        "MIMATIC": {
            "name": "MAI",
            "symbol": "MIMATIC",
            "decimals": 18,
            "address": "0xb84Df10966a5D7e1ab46D9276F55d57bD336AFC7",
            "slots": {
                "balance": "0x5",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/15264/large/mimatic-red.png"
        },
        "SUSHI": {
            "name": "Sushi",
            "symbol": "SUSHI",
            "decimals": 18,
            "address": "0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D",
            "slots": {
                "balance": "0x5",
                "totalSupply": "0x3"
            },
            "image": "https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png"
        },
    },
}