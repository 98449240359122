import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Select as MuiSelect } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Box from "@mui/material/Box";
import { Formik, Field, Form } from "formik";
import { Switch, TextField } from "formik-mui";
import { createContainer } from "../api/home";
import { useState, useRef, useCallback } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import debounce from "lodash.debounce";
import { ethers } from "ethers";
import ReactGA from "react-ga4";

// Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { createTheme } from "@mui/material/styles";

// const darkButton = createTheme({
//   palette: {
//     primary: {
//       main: "#4c4c4c",
//     },
//   },
// });

const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: "rgba(255,255,255,0.87) !important",
        fontSize: 13,
    },
}));

async function getBlockNumber(rpc) {
    
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const chainId = (await provider.getNetwork()).chainId;
    function getLargestPossibleReorg(chainId) {
        // Mainet
        if (chainId === 1) {
            return 5;
        }

        //Goerli
        if (chainId === 5) {
            return 5;
        }

        // Polygon
        if (chainId === 137) {
            return 50;
        }

        // Polygon Mumbai
        if (chainId === 80001) {
            return 50;
        }

        // BSC
        if (chainId === 56) {
            return 50;
        }

    }
    // debugger

    const FALLBACK_MAX_REORG = 200;
    
    const actualMaxReorg = getLargestPossibleReorg(chainId);
    
    const maxReorg = actualMaxReorg ?? FALLBACK_MAX_REORG;

    const latestBlock = await provider.getBlockNumber();
    const lastSafeBlock = latestBlock - maxReorg;
    // debugger
    return lastSafeBlock;
}

const networkRpc = {
    1: ["Ethereum Mainnet", "https://rpc.ankr.com/eth"],
    56: ["Binance Smart Chain", "https://rpc.ankr.com/bsc"],
    137: ["Polygon Mainnet", "https://rpc.ankr.com/polygon"],
    80001: ["Polygon Testnet", "https://rpc.ankr.com/polygon_mumbai"],
    5: ["Goerli Testnet", "https://rpc.ankr.com/eth_goerli"],
    11155111: ["Sepolia Testnet", "https://rpc.sepolia.org/"],
    10: ["Optimism Mainnet", "https://mainnet.optimism.io"],
    42161: ["Arbitrum Mainnet", "https://arb1.arbitrum.io/rpc"],
    // 421613: ["Arbitrum Goerli", "https://goerli-rollup.arbitrum.io/rpc"],
    250: ["Fantom Mainnet", "https://rpc.fantom.network"],
    4002: ["Fantom Testnet", "https://rpc.ankr.com/fantom_testnet"],
    43114: ["Avalanche Network", "https://rpc.ankr.com/avalanche"],
    2222 : ["Kava EVM Co-Chain", "https://evm.kava.io" ],
};

// if (process.env.REACT_APP_BASE_URL === "buildbear.io") {
//   delete networkRpc[11155111];
// }

const ether = ["1", "5", "11155111"];
const poly = ["137", "80001"];
const arbit = ["42161", "421613"];
const optim = ["10"];
const fant = ["250", "4002"];
const bina = ["56"];
const aval = ["43114"];
const kava = ["2222"];

function Config() {
    const [submitting, setSubmitting] = useState(false);
    const formikRef = useRef(null);
    let history = useNavigate();

    const [forkingConfirmLoad, setForkingConfirmLoad] = useState(false);
    const [forkingNetworkName, setForkingNetworkName] = useState(null);
    const [forkingNetworkChainId, setForkingNetworkChainId] = useState(false);
    const [forkingDropdownValue, setForkingDropdownValue] = useState("None");
    const [forkingRpc, setForkingRpc] = useState();
    const [loader, setLoader] = useState(false);

    const [maxBlockNumber, setMaxBlockNumber] = useState(0);

    const [miningChoice, setMiningChoice] = useState("no");

    const [accountChoice, setAccountChoice] = useState("yes");

    const [dialogOpen, setDialogOpen] = useState(false);

    const createPageEvent = () => {
        ReactGA.event({
            category: "BuildBear category",
            action: "Create page Buttton",
        });
    };

    const config = {
        checked: false,
        allowUnlimitedContractSize: false,
        mining: {
            auto: true,
            interval: 0,
        },
        accounts: {
            mnemonic: ethers.Wallet.createRandom().mnemonic.phrase,
        },
        options: {
            hardhat: {
                getStackTraceFailuresCount: true,
                addCompilationResult: true,
                impersonateAccount: true,
                intervalMine: false,
                getAutomine: false,
                stopImpersonatingAccount: true,
                reset: false,
                setLoggingEnabled: true,
                setMinGasPrice: false,
                dropTransaction: false,
                setBalance: false,
                setCode: false,
                setNonce: false,
                setStorageAt: false,
                setNextBlockBaseFeePerGas: false,
                setCoinbase: false,
                mine: true,
            },
            evm: {
                mine: true,
                increaseTime: true,
                setNextBlockTimestamp: true,
                revert: true,
                snapshot: true,
                setAutomine: false,
                setIntervalMining: false,
                setBlockGasLimit: true,
            },
            extra: {
                overrideGas: true,
            },
        },
    };

    const handleChange = (e) => {
        setForkingNetworkName(e);
    };

    function getForkingNetworkName(chainId) {
        // console.log(networkRpc[chainId][0] || networkRpc['default'], "ChainID")
        return networkRpc[chainId] ? networkRpc[chainId][0] : "Unknown";
    }
    // eslint-disable-next-line
    const getForkingChainId = useCallback(
        debounce(async (rpcUrl) => {
            try {
                setForkingNetworkChainId("unknown");
                // setForkingNetworkName(getForkingNetworkName("Unknown"));
                // console.log(rpcUrl);
                const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
                // console.log("1")
                const { chainId } = await provider.getNetwork();
                // console.log("2")
                setForkingNetworkChainId(chainId);
                setForkingNetworkName(getForkingNetworkName(chainId));
            } catch (err) {
                // console.log("Error during Fetching forking chain ID");
                setForkingNetworkName("Couldn't connect to forking URL");
            } finally {
                setForkingConfirmLoad(false);
            }
        }, 1000),
        []
    );

    async function onSubmit(event, checked) {
        try {
            setSubmitting(true);
            formikRef.current.setSubmitting(true);
            const config = formikRef.current.values;

            const sanitizedConfig = JSON.parse(JSON.stringify(config));

            if (sanitizedConfig.forking) {
                if (
                    sanitizedConfig.forking.chainId &&
                    sanitizedConfig.forking.blockNumber
                ) {
                    if (
                        !(
                            Number(sanitizedConfig.forking.chainId) ===
                                sanitizedConfig.forking.chainId &&
                            Number(sanitizedConfig.forking.blockNumber) ===
                                sanitizedConfig.forking.blockNumber
                        )
                    ) {
                        throw new Error(
                            "Forking chainId and blockNumber must be numbers"
                        );
                    }
                } else {
                    throw Error("Forking chainId and blockNumber are required");
                }
            }

            // if (sanitizedConfig.chainId) {
            //   if (!(Number(sanitizedConfig.chainId) === sanitizedConfig.chainId)) {
            //     throw new Error("ChainId must be a number");
            //   }
            // }

            sanitizedConfig.mining.interval *= 1000;

            if (sanitizedConfig.mining.auto) {
                if (sanitizedConfig.mining.interval) {
                    throw new Error(
                        "Cannot set mining interval when auto mining is enabled"
                    );
                }
            } else {
                if (!sanitizedConfig.mining.interval) {
                    throw new Error(
                        "Must set mining interval when auto mining is disabled"
                    );
                }

                const interval = Math.floor(
                    sanitizedConfig.mining.interval / 1000
                );

                if (interval !== sanitizedConfig.mining.interval / 1000) {
                    throw new Error("Mining interval must be whole number");
                }

                if (!(0 < interval && interval < 15)) {
                    throw new Error(
                        "Mining interval must be between 1 and 15 seconds"
                    );
                }
            }

            if (
                !(
                    sanitizedConfig.accounts === [] ||
                    sanitizedConfig.accounts.mnemonic
                )
            ) {
                sanitizedConfig.accounts = [];
            }

            if (sanitizedConfig.accounts.mnemonic) {
                try {
                    ethers.Wallet.fromMnemonic(
                        sanitizedConfig.accounts.mnemonic
                    );
                } catch (_) {
                    throw new Error("Invalid mnemonic");
                }
            }

            if (checked !== undefined) {
                sanitizedConfig.checked = checked;
            }

            // console.log(sanitizedConfig);
            // return; // TODO: To be removed
            const res = await createContainer(sanitizedConfig);
            if (res.err) {
                //TODO: Handle Error
                // console.log(res.err);
                // alert("Sorry, couldn't create a new node");
                toast.error("Sorry, couldn't create a new node", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                // console.log(res);
                if (res.status === 409) {
                    setDialogOpen(true);
                } else if (res.status === 400) {
                    formikRef.current.setFieldValue("checked", false);
                    // alert("Hurray! Your node is being created. It will be live shortly.");
                    toast.error(
                        "Oops! Something went wrong and we couldn't create the test net. We will shortly investigate and reach out to you.  ",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        }
                    );
                    setSubmitting(false);
                    formikRef.current.setSubmitting(false);
                    history("/");
                    return;
                } else {
                    // console.log("Success");
                    formikRef.current.setFieldValue("checked", false);
                    // alert("Hurray! Your node is being created. It will be live shortly.");
                    toast.success(
                        "Hurray! Your node is being created. It will be live shortly.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        }
                    );
                    setSubmitting(false);
                    formikRef.current.setSubmitting(false);
                    history("/");
                    return;
                }
            }
        } catch (err) {
            //TODO: Handle Error
            // console.log(err);
            // alert("Sorry, couldn't create a new node" + "\n" + err);
            toast.error("Sorry, couldn't create a new node" + "\n" + err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setSubmitting(false);
            formikRef?.current?.setSubmitting(false);
        }
    }

    const handleDialogClose = (e) => {
        if (e.target.id === "create") {
            formikRef.current.setFieldValue("checked", true);
            onSubmit(e, true);
        }
        setDialogOpen(false);
    };

    return (
        <Box
            className="configContainer"
            style={{ boxShadow: "none", borderRadius: "16px" }}
        >
            <ConfirmationDialog
                dialogOpen={dialogOpen}
                handleDialogClose={handleDialogClose}
            />
            <Formik initialValues={config} innerRef={formikRef}>
                {({ setFieldValue, values }) => {
                    // useEffect(() => {
                    //   const mnemonic = ethers.Wallet.createRandom().mnemonic;
                    //   setFieldValue("accounts", {
                    //     mnemonic: mnemonic.phrase,
                    //     path: mnemonic.path,
                    //   });
                    // })

                    return (
                        <Form style={{ fontFamily: "Poppins" }}>
                            <Grid
                                container
                                spacing={2}
                                className="gridContainer"
                                justifyContent="center"
                                alignItems="center"
                                textAlign="left"
                            >
                                <Grid item xs={10} style={{ display: "flex" }}>
                                    <Link to="/" style={{ color: "#000" }}>
                                        <i
                                            className="fa fa-arrow-left"
                                            style={{
                                                marginLeft: "-50px",
                                                fontSize: "26px",
                                                marginBottom: "30px",
                                            }}
                                        ></i>
                                    </Link>
                                    <Typography
                                        variant="h4"
                                        fontWeight={700}
                                        style={{
                                            textTransform: "uppercase",
                                            fontFamily: "Poppins",
                                            textAlign: "center",
                                        }}
                                    >
                                        Customise your Private BuildBear Net
                                    </Typography>
                                </Grid>

                                <Grid item xs={10} style={{ display: "block" }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={10}>
                                    <div
                                        style={{
                                            backgroundColor: "#fff",
                                            boxShadow: "none",
                                            padding: "15px",
                                            borderRadius: "16px",
                                            marginLeft: "-10px",
                                        }}
                                    >
                                        <Grid item xs={10}>
                                            <Typography
                                                variant="h5"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Forking Options{" "}
                                            </Typography>
                                        </Grid>
                                        <hr />
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "20px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={5}
                                                style={{
                                                    maxWidth: "max-content",
                                                    minWidth: "484px",
                                                    margin: "10px 20px 10px 10px",
                                                    padding: "0px 0px 0px 15px",
                                                }}
                                            >
                                                <Typography>
                                                    Forking From:{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid item xs={7}>
                                                    <MuiSelect
                                                        name="forkingURL"
                                                        size="small"
                                                        fullWidth
                                                        value={
                                                            forkingDropdownValue
                                                        }
                                                        onChange={async (e) => {
                                                            // setForkingConfirmLoad(true);
                                                            // setFieldValue('forking.url', e.target.value);
                                                            setForkingDropdownValue(
                                                                e.target.value
                                                            );
                                                            setForkingRpc(
                                                                undefined
                                                            );
                                                            // handleChange(e);
                                                            const value =
                                                                e.target.value;
                                                            if (
                                                                value === "None"
                                                            ) {
                                                                setFieldValue(
                                                                    "forking",
                                                                    undefined
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Ethereum"
                                                            ) {
                                                                setForkingRpc(
                                                                    "1"
                                                                );
                                                                const value =
                                                                    "1";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Polygon"
                                                            ) {
                                                                setForkingRpc(
                                                                    "137"
                                                                );
                                                                const value =
                                                                    "137";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/polygon");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Arbitrum"
                                                            ) {
                                                                setForkingRpc(
                                                                    "42161"
                                                                );
                                                                const value =
                                                                    "42161";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Optimism"
                                                            ) {
                                                                setForkingRpc(
                                                                    "10"
                                                                );
                                                                const value =
                                                                    "10";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Fantom"
                                                            ) {
                                                                setForkingRpc(
                                                                    "250"
                                                                );
                                                                const value =
                                                                    "250";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            } else if (
                                                                value ===
                                                                "Binance"
                                                            ) {
                                                                setForkingRpc(
                                                                    "56"
                                                                );
                                                                const value =
                                                                    "56";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }
                                                            

                                                            else if (
                                                                value ===
                                                                "Avalanche"
                                                            ) {
                                                                setForkingRpc(
                                                                    "43114"
                                                                );
                                                                const value =
                                                                    "43114";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }

                                                            else if (
                                                                value ===
                                                                "Kava"
                                                            ) {
                                                                setForkingRpc(
                                                                    "2222"
                                                                );
                                                                const value =
                                                                    "2222";
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                // const blockNumber = await getBlockNumber("https://rpc.ankr.com/eth");
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }

                                                            else if (
                                                                value ===
                                                                "Custom"
                                                            ) {
                                                                // const rpc = "";
                                                                // setForkingConfirmLoad(true);
                                                                // setFieldValue('forking.url', rpc);
                                                                setFieldValue(
                                                                    "forking",
                                                                    undefined
                                                                );
                                                                // try {
                                                                //   let blockNumber;
                                                                //   try {
                                                                //     blockNumber = (await new ethers.providers.JsonRpcProvider(rpc).getBlock("finalized")).number;
                                                                //   } catch (_) {
                                                                //     blockNumber = (await new ethers.providers.JsonRpcProvider(rpc).getBlock("latest")).number;
                                                                //   }
                                                                //   setFieldValue('forking.blockNumber', blockNumber)
                                                                // }
                                                                // catch (_) { }
                                                                // handleChange(rpc);
                                                            } else {
                                                                // console.log("Zero")
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={"None"}
                                                        >
                                                            None
                                                        </MenuItem>
                                                        {/* {Object.keys(networkRpc).map((key) => {
                              return (
                                <MenuItem value={key}>{networkRpc[key][0]}</MenuItem>
                              );
                            })} */}
                                                        <MenuItem
                                                            value={"Ethereum"}
                                                        >
                                                            Ethereum
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Polygon"}
                                                        >
                                                            Polygon
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Arbitrum"}
                                                        >
                                                            Arbitrum
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Optimism"}
                                                        >
                                                            Optimism
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Fantom"}
                                                        >
                                                            Fantom
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Binance"}
                                                        >
                                                            Binance
                                                        </MenuItem>
                                                        <MenuItem
                                                        value={"Avalanche"}
                                                        >
                                                            Avalanche
                                                        </MenuItem>
                                                        <MenuItem
                                                        value={"Kava"}
                                                        >
                                                            Kava
                                                        </MenuItem>
                                                
                                                        <MenuItem
                                                            value={"Custom"}
                                                        >
                                                            Custom
                                                        </MenuItem>
                                                
                                                    </MuiSelect>
                                                </Grid>
                                                {/* <Field
                        component={TextField}
                        name="forking.url"
                        type="text"
                        size="small"
                        helperText="Leave this empty to create a blank node"
                        onChange={(e) => {
                          setForkingConfirmLoad(true);
                          setFieldValue('forking.url', e.target.value);
                          getForkingChainId(e.target.value);
                        }}
                      /> */}
                                            </Grid>
                                        </div>
                                        <div
                                            className="forkingConfirmation"
                                            style={{
                                                display: values?.forking?.url
                                                    ? "flex"
                                                    : "none",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <Grid container>
                                                {forkingConfirmLoad ? (
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        textAlign="center"
                                                    >
                                                        <CircularProgress />
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        {/* <Grid item xs={6} style={{ maxWidth: 'max-content', minWidth: '484px', margin: '10px 20px 10px 10px', padding: '0px 0px 0px 15px' }}>
                                  <Typography>Forking Details: </Typography>
                                </Grid> */}
                                                        <Grid
                                                            item
                                                            container
                                                            xs={6}
                                                            alignItems="center"
                                                            style={{
                                                                maxWidth:
                                                                    "max-content",
                                                                margin: "10px 20px 10px 10px",
                                                            }}
                                                        >
                                                            <Grid item xs={12}>
                                                                {
                                                                    forkingNetworkName
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                Chain ID :{" "}
                                                                {
                                                                    forkingNetworkChainId
                                                                }
                                                            </Grid>
                                                            {/* {
                                  forkingNetworkName !== 'Unknown Network' &&
                                  forkingNetworkName !== "Couldn't connect to forking URL" &&
                                  (
                                    <>
                                      <Grid item xs={3}>
                                        <ThemeProvider theme={darkButton}>
                                          <Button
                                            size="small"
                                            variant={confirmedForkingChainId ? 'contained' : 'outlined'}
                                            onClick={() => { setConfirmedForkingChainId(true) }}
                                          >YES</Button>
                                        </ThemeProvider>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <ThemeProvider theme={darkButton}>
                                          <Button
                                            // justifySelf='left'
                                            size="small"
                                            variant={!confirmedForkingChainId ? 'contained' : 'outlined'}
                                            onClick={() => { setConfirmedForkingChainId(false) }}
                                          >NO</Button>
                                        </ThemeProvider>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Tooltip
                                          title={`Choosing YES will allow us to fetch ABI from ${forkingNetworkName}.
                                    Your Node will still be a forking node even if you choose NO.`}
                                          arrow
                                          placement="top"
                                        >
                                          <IconButton styles={{ fontSize: '10px' }}>
                                            <HelpOutlineIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </>
                                  )
                                } */}
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </div>
                                        {forkingDropdownValue === "Ethereum" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {/* {console.log(forkingRpc)} */}
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    ether.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            //  disabled={key === '11155111'}
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Polygon" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {/* {console.log(forkingRpc)} */}
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    poly.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Arbitrum" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    arbit.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Optimism" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    optim.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Fantom" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    fant.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Binance" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    bina.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            {/* <MenuItem value={"Mainnet"}>Mainnet</MenuItem>
                                <MenuItem value={"Goerli"}>Goerli</MenuItem> */}
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Avalanche" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                    aval.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                 
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                         {forkingDropdownValue === "Kava" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Chain:{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid item xs={7}>
                                                        <MuiSelect
                                                            name="forkingURL"
                                                            size="small"
                                                            fullWidth
                                                            value={forkingRpc}
                                                            onChange={async (
                                                                e
                                                            ) => {
                                                                setForkingRpc(
                                                                    e.target
                                                                        .value
                                                                );
                                                                // handleChange(e);
                                                                // console.log(e.target.value)
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                const rpc =
                                                                    networkRpc[
                                                                        value
                                                                    ][1];
                                                                setForkingConfirmLoad(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "forking.chainId",
                                                                    Number(
                                                                        value
                                                                    )
                                                                );
                                                                setLoader(true);
                                                                const blockNumber =
                                                                    await getBlockNumber(
                                                                        rpc
                                                                    );
                                                                setFieldValue(
                                                                    "forking.blockNumber",
                                                                    blockNumber
                                                                );
                                                                setLoader(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    rpc
                                                                );
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                networkRpc
                                                            )
                                                                .filter((key) =>
                                                                kava.includes(
                                                                        key
                                                                    )
                                                                )
                                                                .map((key) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                networkRpc[
                                                                                    key
                                                                                ][0]
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                 
                                                        </MuiSelect>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingDropdownValue === "Custom" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "484px",
                                                        margin: "10px 20px 10px 10px",
                                                        padding:
                                                            "0px 0px 0px 15px",
                                                    }}
                                                ></Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Contact us at
                                                        team@buildbear.io
                                                    </Typography>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {forkingRpc ? (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: "20px",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        style={{
                                                            maxWidth:
                                                                "max-content",
                                                            minWidth: "484px",
                                                            margin: "10px 20px 10px 10px",
                                                            padding:
                                                                "0px 0px 0px 15px",
                                                        }}
                                                    >
                                                        <Typography>
                                                            Fork from
                                                            Blocknumber:{" "}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {loader ? (
                                                            <CircularProgress />
                                                        ) : (
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                name="forking.blockNumber"
                                                                type="number"
                                                                size="small"
                                                                fullWidth
                                                                inputProps={{
                                                                    min:
                                                                        maxBlockNumber -
                                                                            10_000 >
                                                                        0
                                                                            ? maxBlockNumber -
                                                                              10_000
                                                                            : 0,
                                                                    max: maxBlockNumber,
                                                                }}
                                                                validate={async (
                                                                    x
                                                                ) => {
                                                                    let error;

                                                                    if (!x) {
                                                                        error =
                                                                            "Invalid BlockNumber";
                                                                    } else {
                                                                        const rpc =
                                                                            networkRpc[
                                                                                values
                                                                                    .forking
                                                                                    .chainId
                                                                            ][1];
                                                                        const blockNumber =
                                                                            await getBlockNumber(
                                                                                rpc
                                                                            );

                                                                        setMaxBlockNumber(
                                                                            blockNumber
                                                                        );

                                                                        if (
                                                                            blockNumber &&
                                                                            x >
                                                                                blockNumber
                                                                        ) {
                                                                            error =
                                                                                "Invalid BlockNumber";
                                                                        }
                                                                    }
                                                                    return error;
                                                                }}
                                                            />
                                                        )}

                                                        <DarkToolTip
                                                            title={`This is the latest confirmed blocknumber on the network from which you can create a BuildBear Net`}
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                styles={{
                                                                    fontSize:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <HelpOutlineIcon />
                                                            </IconButton>
                                                        </DarkToolTip>
                                                    </Grid>
                                                </div>

                                                {/* // NOTE: This is being commented out cause metamask sends enormous amount of batch
                          // requests to check the token state and balances, when the chain ID of the chain is
                          // a mainnet chain ID. To prevent this we are *for now* removing the option to set
                          // the same chain ID as the mainnet for the user. */}
                                                {/* <div style={{ display: 'flex', marginBottom: '20px' }}>
                            <Grid item xs={5} style={{ maxWidth: 'max-content', minWidth: '484px', margin: '10px 20px 10px 10px', padding: '0px 0px 0px 15px' }}>
                              <Typography>Do you want the same Chain ID?</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Field
                                component={Switch}
                                name="chainId"
                                checked={
                                  values.chainId && values.forking && values.chainId === values.forking.chainId
                                }
                                onChange={(e) => {
                                  setFieldValue('chainId', e.target.checked ? values.forking?.chainId : undefined);
                                }}
                                type="checkbox"
                              />
                            </Grid>
                          </div> */}
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {/* <Grid item xs={10}>
                    <Divider />
                  </Grid> */}
                                    </div>
                                </Grid>

                                {/* <Grid item xs={10}>
                  <Divider />
                </Grid> */}

                                <Grid item xs={10}>
                                    <Accordion
                                        style={{
                                            backgroundColor: "#fff",
                                            boxShadow: "none",
                                            padding: "15px",
                                            borderRadius: "16px",
                                            marginLeft: "-10px",
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{ px: 0 }}
                                        >
                                            <Typography
                                                variant="h5"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Advanced Options{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <hr
                                                style={{ marginLeft: "-10px" }}
                                            />
                                            {/* <div style={{ display: 'flex', marginBottom: '20px' }}>
                        <Grid item xs={5} style={{ maxWidth: 'max-content', minWidth: '468px', margin: '10px 20px 10px 10px' }}>
                          <Typography>Do you want to have a specific Chain ID?</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Field
                            component={TextField}
                            name="chainId"
                            type="number"
                            size="small"
                            placeholder="Leave Blank for an auto computed Chain ID"
                          />
                        </Grid>
                      </div> */}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Deploy Contracts that
                                                        are bigger than the 24KB
                                                        max?{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Field
                                                        component={Switch}
                                                        name="allowUnlimitedContractSize"
                                                        type="checkbox"
                                                    />
                                                </Grid>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you want a specific
                                                        mining window for your
                                                        testnet?{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={5}>
                                                    <Grid item xs={5}>
                                                        <MuiSelect
                                                            name="miningChoice"
                                                            size="small"
                                                            value={miningChoice}
                                                            onChange={(e) => {
                                                                setMiningChoice(
                                                                    e.target
                                                                        .value
                                                                );
                                                                if (
                                                                    e.target
                                                                        .value ===
                                                                    "no"
                                                                ) {
                                                                    setFieldValue(
                                                                        "mining.auto",
                                                                        true
                                                                    );
                                                                    setFieldValue(
                                                                        "mining.interval",
                                                                        0
                                                                    );
                                                                } else if (
                                                                    e.target
                                                                        .value ===
                                                                    "yes"
                                                                ) {
                                                                    setFieldValue(
                                                                        "mining.auto",
                                                                        false
                                                                    );
                                                                    setFieldValue(
                                                                        "mining.interval",
                                                                        1
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value={"no"}
                                                            >
                                                                No
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={"yes"}
                                                            >
                                                                Yes
                                                            </MenuItem>
                                                        </MuiSelect>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {miningChoice ===
                                                        "yes" ? (
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                name="mining.interval"
                                                                size="small"
                                                                type="number"
                                                                helperText="Enter Mining Interval in Seconds"
                                                                validate={(
                                                                    x
                                                                ) => {
                                                                    if (
                                                                        x === 0
                                                                    ) {
                                                                        return "Mining Interval cannot be 0 seconds";
                                                                    }
                                                                    if (!x) {
                                                                        return "Mining Interval required";
                                                                    }
                                                                    if (
                                                                        x > 15
                                                                    ) {
                                                                        return "Mining Interval cannot be greater than 15 seconds";
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you need unlocked
                                                        accounts?
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={5}>
                                                        <MuiSelect
                                                            name="accountChoice"
                                                            size="small"
                                                            value={
                                                                accountChoice
                                                            }
                                                            onChange={(e) => {
                                                                setAccountChoice(
                                                                    e.target
                                                                        .value
                                                                );
                                                                if (
                                                                    e.target
                                                                        .value ===
                                                                    "no"
                                                                ) {
                                                                    setFieldValue(
                                                                        "accounts",
                                                                        []
                                                                    );
                                                                } else if (
                                                                    e.target
                                                                        .value ===
                                                                    "yes"
                                                                ) {
                                                                    const mnemonic =
                                                                        ethers.Wallet.createRandom()
                                                                            .mnemonic;
                                                                    setFieldValue(
                                                                        "accounts",
                                                                        {
                                                                            mnemonic:
                                                                                mnemonic.phrase,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value={"no"}
                                                            >
                                                                No
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={"yes"}
                                                            >
                                                                Yes
                                                            </MenuItem>
                                                        </MuiSelect>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {accountChoice ===
                                                        "yes" ? (
                                                            <Grid
                                                                container
                                                                item
                                                                spacing={2}
                                                                textAlign="left"
                                                                style={{
                                                                    marginTop: 1,
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                >
                                                                    <Field
                                                                        component={
                                                                            TextField
                                                                        }
                                                                        name="accounts.mnemonic"
                                                                        size="small"
                                                                        helperText="Mnemonic"
                                                                        validate={(
                                                                            x
                                                                        ) => {
                                                                            try {
                                                                                ethers.Wallet.fromMnemonic(
                                                                                    x
                                                                                );
                                                                            } catch (_) {
                                                                                return "Wrong Mnemonic";
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you want to override
                                                        Gas Estimation to detect
                                                        transaction failure
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Field
                                                        component={Switch}
                                                        name="overrideGas"
                                                        checked={
                                                            values.options.extra
                                                                .overrideGas
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "options.extra.overrideGas",
                                                                e.target.checked
                                                            );
                                                        }}
                                                        type="checkbox"
                                                    />
                                                </Grid>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you want to permit
                                                        Impersonation
                                                        Transactions?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Field
                                                        component={Switch}
                                                        name="impersonation"
                                                        checked={
                                                            values.options
                                                                .hardhat
                                                                .impersonateAccount &&
                                                            values.options
                                                                .hardhat
                                                                .stopImpersonatingAccount
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "options.hardhat.impersonateAccount",
                                                                e.target.checked
                                                            );
                                                            setFieldValue(
                                                                "options.hardhat.stopImpersonatingAccount",
                                                                e.target.checked
                                                            );
                                                        }}
                                                        type="checkbox"
                                                    />
                                                    <DarkToolTip
                                                        title={`In a blockchain simulation you can impersonate as any other account and execute a transaction from that account.  This will permit you to impersonate as any other account, eg, Vitalik’s Account and vice-versa`}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            styles={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </DarkToolTip>
                                                </Grid>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you want to permit
                                                        Snapshot?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Field
                                                        component={Switch}
                                                        name="snapshot"
                                                        checked={
                                                            values.options.evm
                                                                .snapshot &&
                                                            values.options.evm
                                                                .revert
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "options.evm.snapshot",
                                                                e.target.checked
                                                            );
                                                            setFieldValue(
                                                                "options.evm.revert",
                                                                e.target.checked
                                                            );
                                                        }}
                                                        type="checkbox"
                                                    />
                                                    <DarkToolTip
                                                        title={`In a blockchain simulation you can take snapshot of the state of the blockchain environment and execute transactions in the respective snapshot, you can then revert the snapshot and revert to the earlier state`}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            styles={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </DarkToolTip>
                                                </Grid>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    style={{
                                                        maxWidth: "max-content",
                                                        minWidth: "468px",
                                                        margin: "10px 20px 10px 10px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Do you want to the
                                                        ability to mine any
                                                        number of blocks and
                                                        manipulate block
                                                        timestamp?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Field
                                                        component={Switch}
                                                        name="miningAndTime"
                                                        checked={
                                                            values.options
                                                                .hardhat.mine &&
                                                            values.options.evm
                                                                .mine &&
                                                            values.options.evm
                                                                .increaseTime &&
                                                            values.options.evm
                                                                .setNextBlockTimestamp
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "options.hardhat.mine",
                                                                e.target.checked
                                                            );
                                                            setFieldValue(
                                                                "options.evm.mine",
                                                                e.target.checked
                                                            );
                                                            setFieldValue(
                                                                "options.evm.increaseTime",
                                                                e.target.checked
                                                            );
                                                            setFieldValue(
                                                                "options.evm.setNextBlockTimestamp",
                                                                e.target.checked
                                                            );
                                                        }}
                                                        type="checkbox"
                                                    />
                                                    <DarkToolTip
                                                        title={`In a blockchain simulation you can programmatically mine one or multiple empty blocks and / or change the time stamp of the next block, thus manipulating the time of the blockchain`}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            styles={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </DarkToolTip>
                                                </Grid>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid item xs={10}></Grid>
                                <Grid item xs={10}></Grid>

                                <Grid item xs={3} textAlign="center">
                                    <LoadingButton
                                        variant="outlined"
                                        color="primary"
                                        className="create-submit"
                                        size="large"
                                        // onClick={onSubmit,  createPageEvent()}
                                        onClick={() => {
                                            onSubmit();
                                            createPageEvent();
                                        }}
                                        loading={submitting}
                                    >
                                        Create
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
}

function ConfirmationDialog({ dialogOpen, handleDialogClose }) {
    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle id="alert-dialog-title">
                {"Proceed with node creation?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Creating a Private Testnet with the same Chain ID (from the
                    chain that is being forked) is likely to conflict with the
                    pre-existing Chain IDs in Metamask Wallet.
                    <br />
                    Thus, this may require you to manually add the network in
                    the Metamask Wallet or require you to change the RPC url of
                    that particular network.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} id="cancel">
                    Cancel
                </Button>
                <Button onClick={handleDialogClose} id="create" autoFocus>
                    Proceed and create node
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Config;
