import * as React from 'react';
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import "../styles/Node.css";
import { requestCurrency, requestCurrencyERC20, getNodeRPC, getExplorerUrl } from "../api/faucet";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import NotFound from "./NotFound";
import Navbar from "../components/Navbar";
import useSigner from "../hooks/useSigner";
import useConnectWallet from "../hooks/useConnectWallet";
// import Dropdown from 'react-bootstrap/Dropdown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
import Constant from "./Constant";
import { ReactComponent as Metamask } from "../assets/metamask.svg";

//Icons
import Binance from "../assets/chain/binance.svg";
import Etherium from "../assets/chain/etherium.svg";
import Arbit from "../assets/chain/arbit.svg";
import Fandom from "../assets/chain/fandom.svg";
import Polygon from "../assets/chain/polygon.svg";
import Optimism from "../assets/chain/optimism.svg";
import Avalanche from "../assets/chain/avalanche.svg";
import Kava from "../assets/chain/kava.svg";

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const networkRpc = {
  1: ["Ethereum Mainnet", "https://rpc.ankr.com/eth", Etherium],
  56: ["Binance Smart Chain", "https://rpc.ankr.com/bsc", Binance],
  137: ["Polygon Mainnet", "https://rpc.ankr.com/polygon", Polygon],
  80001: ["Polygon Testnet", "https://rpc.ankr.com/polygon_mumbai", Polygon],
  5: ["Goerli Testnet", "https://rpc.ankr.com/eth_goerli", Etherium],
  11155111: ["Sepolia Testnet", "https://rpc.sepolia.org/", Etherium],
  10: ["Optimism Mainnet", "https://mainnet.optimism.io", Optimism],
  42161: ["Arbitrum Mainnet", "https://arb1.arbitrum.io/rpc", Arbit],
  421613: ["Arbitrum Goerli", "https://goerli-rollup.arbitrum.io/rpc", Arbit],
  250: ["Fantom Mainnet", "https://rpc.fantom.network", Fandom],
  4002: ["Fantom Testnet", "https://rpc.ankr.com/fantom_testnet", Fandom],
  43114: ["Avalanche Network", "https://rpc.ankr.com/avalanche", Avalanche],
  2222: ["Kava EVM Co-Chain", "https://evm.kava.io", Kava],
}

const Node = () => {
  const { nodeHash } = useParams();
  const signer = useSigner();
  const [connectWallet] = useConnectWallet((account) => setAddress(account));

  const [address, setAddress] = useState("");
  const [ERC20Address, setERC20Address] = useState("");

  const [amount, setAmount] = useState('100');
  const [ERC20Amount, setERC20Amount] = useState('100');

  const [loading, setLoading] = useState(false);
  const [loadingERC20, setLoadingERC20] = useState(false);

  const [amountErr, setAmountErr] = useState(null);
  const [ERC20AmountErr, setERC20AmountErr] = useState(null);

  const [addressErr, setAddressErr] = useState(null);
  const [ERC20AddressErr, setERC20AddressErr] = useState(null);

  const [isValidHash, setIsValidHash] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  const [forkingChainId, setForkingChainId] = useState(null);

  const [tokens, setTokens] = useState([]);

  const [selectedToken, setSelectedToken] = useState(null);
  const [constantData, setConstantData] = useState(null);

  const handleChange = (event) => {
    setSelectedToken(event.target.value);
  }

  const [metamaskLock, setMetamaskLock] = useState();

  async function checkMetamaskLock() {
    const { ethereum } = window
    const accounts = await ethereum._metamask.isUnlocked();
    if (accounts) {
      setMetamaskLock(true)
    }
    else {
      setMetamaskLock(false)
    }
    // console.log(accounts);
  }

  useEffect(() => {
    checkMetamaskLock()
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", checkMetamaskLock);
    // console.log(checkMetamaskLock)
    return () => document.removeEventListener("visibilitychange", checkMetamaskLock);
  }, []);

  // useEffect(() => {
  //   const savedAddress = localStorage.getItem("savedAddress");
  //   if (savedAddress) {
  //     setAddress(savedAddress);
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("savedAddress", address);
  // }, [address]);

  async function connectMetaMask(nodeHash) {
    if (!window.ethereum) {
      // alert("Metamask not found. Please Install it.");
      toast.error('Metamask not found. Please Install it.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const provider = new ethers.providers.JsonRpcProvider({ url: getNodeRPC(nodeHash), timeout: 5000 });
    const { chainId } = await provider.getNetwork();
    let formattedName = null;
    try {
      formattedName = nodeHash
        .split("_")
        .slice(0, -1)
        .join(" ");
    } catch (err) {
      // console.log("Error during name formating");
    }

    if (!formattedName) {
      formattedName = nodeHash;
    }
    window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainName: `BuildBear ${formattedName}`,
            chainId: ethers.utils.hexlify(chainId),
            rpcUrls: [getNodeRPC(nodeHash)],
            blockExplorerUrls: [getExplorerUrl(nodeHash)],
            nativeCurrency: {
              name: "BB Ether",
              symbol: "BB ETH",
              decimals: 18,
            },
          },
        ],
      })
      .then((data) => {
        // console.log(data);
        if (data === null) {
          // alert("Successfully added.");
          toast.success('Successfully added.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          checkMetamaskLock()
        }
        else {
          // alert("Unable to add.");
          toast.error('Unable to add.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => {
        if (e.code === 4902) {
          // console.log("network is not available, add it");
        } else {
          // console.log("could not set network");
        }
        // alert("Unable to add network");
        toast.error('Unable to add network', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // console.log(e);
      });
  }

  useEffect(() => {
    if (selectedToken && selectedToken !== "Custom") {
      setERC20Address(Constant[forkingChainId][selectedToken].address);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);


  const [connectedAccount, setConnectedAccount] = useState(null);
  useEffect(() => {
    async function validateNodeHash() {
      setPageLoading(true);
      const rpc = getNodeRPC(nodeHash);
      const provider = new ethers.providers.JsonRpcProvider({ url: rpc, timeout: 5000 });
      // console.log(provider);
      try {
        await provider.getNetwork();
        setIsValidHash(true);
      } catch (err) {
        // console.log(err);
        setIsValidHash(false);
      } finally {
        setPageLoading(false);
      }
    }

    async function setChainIdAndTokens() {
      const url = getNodeRPC(nodeHash);
      const response = await fetch(url, {
        method: "get"
      });

      if (response.status === 200) {
        const data = await response.json();
        const fci = data.chainId;
        setForkingChainId(fci.toString());
        // console.log("test", Object.keys(Constant[data.chainId]));
        const tokens = Object.keys(Constant[data.chainId.toString()]);
        const tokenData = Constant[fci.toString()] || {};
        setConstantData(tokenData);
        setTokens(tokens);
        setSelectedToken(tokens[0]);
      } else {
        setSelectedToken("Custom");
      }
    }

    validateNodeHash();
    setChainIdAndTokens()
  }, [nodeHash]);

  useEffect(() => {
    (async function () {
      if (signer) {
        const account = await signer.getAddress();
        // console.log(account);

        setConnectedAccount(account);
      } else {
        setConnectedAccount(null);
      }
    })();
  }, [signer]);

  function validate() {
    setAmountErr(null);
    setAddressErr(null);
    if (!amount) {
      setAmountErr("Amount is required");
      return false;
    }
    if (amount < 0) {
      setAmountErr("Amount cannot be negative");
      return false;
    }
    if (!address) {
      setAddressErr("Address is Required");
      return false;
    }
    if (!ethers.utils.isAddress(address)) {
      setAddressErr("Invalid Address");
      return false;
    }
    if (!(address.slice(0, 2) === "0x")) {
      setAddressErr("Address should start with '0x'");
      return false;
    }
    return true;
  }

  async function validateERC20() {
    setERC20AmountErr(null);
    setERC20AddressErr(null);
    setAddressErr(null);
    if (!ERC20Amount) {
      setERC20AmountErr("Amount is required");
      return false;
    }
    if (ERC20Amount < 0) {
      setERC20AmountErr("Amount cannot be negative");
      return false;
    }
    if (!ERC20Address) {
      setERC20AddressErr("Address is Required");
      return false;
    }
    if (!ethers.utils.isAddress(ERC20Address)) {
      setERC20AddressErr("Invalid Address");
      return false;
    }
    if (!(ERC20Address.slice(0, 2) === "0x")) {
      setERC20AddressErr("Address should start with '0x'");
      return false;
    }
    if (!address) {
      setAddressErr("Address is Required");
      return false;
    }
    if (!ethers.utils.isAddress(address)) {
      setAddressErr("Invalid Address");
      return false;
    }
    if (!(address.slice(0, 2) === "0x")) {
      setAddressErr("Address should start with '0x'");
      return false;
    }
    // vaild contract having all 5 functions
    return true;
  }

  async function getToken(e) {
    try {
      if (!validate()) {
        return;
      }
      setLoading(true);
      const response = await requestCurrency(nodeHash, address, Number(amount));
      if (response.err) {
        // alert("Sorry there was an error");
        toast.error('Sorry there was an error', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // alert("Success, New Balance: "
        //   + response.balance
        // );
        toast.success("Success, New Balance: " + response.balance, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      // console.log(err);
      // alert("Sorry there was an error");
      toast.error('Sorry there was an error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  async function getERC20Token(e) {
    try {
      if (!await validateERC20()) {
        return;
      }
      setLoadingERC20(true);
      // console.log("Reached here");
      const response = await requestCurrencyERC20(
        nodeHash,
        address,
        Number(ERC20Amount),
        ERC20Address,
        // Constant[forkingChainId] ? (Constant[forkingChainId][selectedToken] ? Constant[forkingChainId][selectedToken].slots : undefined) : undefined
      );
      if (response.err) {
        // alert("Sorry there was an error");
        toast.error('Sorry there was an error', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // alert("Success, New Balance: "
        //   + response.balance
        //   + "\n\nNote: Metamask may not update your balance immediately"
        // );
        toast.success("Success, New Balance: " + response.balance
          + "\n\nNote: Metamask may not update your balance immediately", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      // console.log(err);
      // alert("Sorry there was an error");
      toast.error('Sorry there was an error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoadingERC20(false);
    }
  }

  if (pageLoading) {
    return (
      <Box className="wrapper">
        <Stack
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ height: "75vh", fontSize: "16px" }}
        >
          <CircularProgress />
        </Stack>
      </Box>
    );
  }

  if (!isValidHash) {
    return <NotFound />;
  }
  const temp = nodeHash;

  function testnetName(temp) {
    if (temp.includes("_")) {
      return temp.replaceAll("_", " ").slice(0, -9);
    } else {
      return temp;
    }
  }

  return (
    <>
      <Navbar />
      <Box className="wrapper">
        <Stack
          className="faucet-container"
          alignItems="left"
          justifyContent="space-evenly"
          sx={{ height: "100vh", fontSize: "16px" }}
        >
          <Typography variant="h2" style={{ fontSize: "20px" }}>
            BUILDBEAR FAUCET
          </Typography>
          <Typography
            style={{ fontSize: "24px", color: "#403F3F" }}
            className="faucet-name"
          > {`Testnet Name: ${testnetName(temp)}`}</Typography>
          {/* {(() => {
            if (forkingChainId) { */}
          {
            forkingChainId ? <Typography variant="h3" style={{ fontSize: "15px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Forked From {(() => {
                if (networkRpc[Number(forkingChainId)]) {
                  return (
                    <>
                      {networkRpc[Number(forkingChainId)][0]}
                      <img src={networkRpc[Number(forkingChainId)][2]} alt={networkRpc[Number(forkingChainId)][0]} style={{ marginLeft: "5px", height: "24px" }} />
                    </>
                  )
                } else {
                  return "Chain ID " + forkingChainId;
                }
              })()}
            </Typography> : ""
          }

          {/* }
          })()} */}

          <br />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent
                  style={{ backgroundColor: "#E8E8E8" }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
                    <TextField
                      style={{
                        // backgroundColor: "#fff",
                        width: "400px",
                        borderRadius: "8px",
                        color: "#000",
                        border: "3px solid #fff",
                        padding: "8px 10px",
                      }}
                      variant="standard"
                      placeholder="Input Account Address here"
                      error={!!addressErr}
                      helperText={addressErr}
                      color="primary"
                      sx={{
                        minWidth: "44ch",
                      }}
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                    {/* <span>OR</span> */}
                    {!connectedAccount && (
                      <Button className="faucet-button" variant="outlined" onClick={connectWallet} size="small">
                        Connect your wallet
                      </Button>
                    )}

                    {connectedAccount && (
                      <Button
                        className="faucet-button"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setAddress(connectedAccount);
                        }}
                      >
                        {"Choose "}
                        {connectedAccount}
                      </Button>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent
                  style={{ backgroundColor: "#E8E8E8", paddingBottom: "39px" }}
                >
                  <Typography
                    style={{ fontSize: "24px", color: "#403F3F" }}
                  >{`Native Token`}</Typography>
                  <br />
                  <TextField
                    placeholder="Enter Amount in ETH over here"
                    type="number"
                    variant="standard"
                    error={!!amountErr}
                    helperText={amountErr}
                    style={{
                      // backgroundColor: "#fff",
                      borderRadius: "8px",
                      borderColor: "#fff",
                      border: "3px solid #fff",
                      padding: "8px 10px",
                    }}
                    color="primary"
                    value={amount}
                    sx={{
                      minWidth: "46ch",
                    }}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    onClick={getToken}
                    color="primary"
                    className="faucet-button"
                  >
                    Get BB ETH
                  </LoadingButton>
                  <br />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ position: "relative" }} item xs={6}>
              {/* <div class="ribbon ribbon-top-right">
                <span>Currently In Beta</span>
              </div> */}
              <Card sx={{ minWidth: 275 }} >
                <CardContent
                  style={{ backgroundColor: "#E8E8E8", height: '22.55rem' }}
                >
                  <Typography
                    style={{ fontSize: "24px", color: "#403F3F" }}
                  >{`ERC20 Token`}</Typography>
                  <br />
                  <TextField
                    placeholder="Enter Amount of ERC20 Tokens over here"
                    type="number"
                    variant="standard"
                    error={!!ERC20AmountErr}
                    helperText={ERC20AmountErr}
                    color="primary"
                    style={{
                      // backgroundColor: "#fff",
                      border: "3px solid #fff",
                      borderRadius: "8px",
                      padding: "8px 10px",
                    }}
                    value={ERC20Amount}
                    sx={{
                      minWidth: "46ch",
                    }}
                    onChange={(e) => {
                      setERC20Amount(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  {/* {forkingChainId ? */}
                  {
                    forkingChainId && <TextField
                      className='token-dropdown'
                      value={selectedToken}
                      onChange={handleChange}
                      select
                      style={{
                        width: "200px", height: "40px", color: 'black', backgroundColor: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      {console.log("tokens", tokens)}
                      {/* {tokens.map(data =>
                        (<MenuItem className='token-dropdown' value={data} style={{ backgroundColor: "#E8E8E8" }}>{data} </MenuItem>)
                      )} */}
                      {tokens.map(data => (
                        <MenuItem className='token-dropdown' value={data} style={{ backgroundColor: "#fff" }}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <img src={constantData[data]?.image} alt={data} style={{ height: "16px" }} />
                            <span style={{ fontWeight: 'bold' }}>{data}</span>
                          </div>
                        </MenuItem>
                      ))}
                      <MenuItem value={"Custom"} style={{ backgroundColor: "#fff" }}>Custom</MenuItem>
                    </TextField>
                  }

                  {/* : "" */}
                  {/* } */}
                  <br />
                  <br />

                  <TextField
                    placeholder="Enter Address of the ERC20 Token"
                    variant="standard"
                    error={!!ERC20AddressErr}
                    helperText={ERC20AddressErr}
                    color="primary"
                    style={{
                      // backgroundColor: "#fff",
                      borderRadius: "8px",
                      border: "3px solid #fff",
                      padding: "8px 10px",
                    }}
                    value={ERC20Address}
                    sx={{
                      minWidth: "50ch",
                    }}
                    onChange={(e) => {
                      const address = e.target.value;
                      setERC20Address(address);
                      const image = Object.values(Constant[forkingChainId]).find((token) => token.image === address);
                      console.log("image", image)
                      const token = Object.values(Constant[forkingChainId]).find((token) => token.address === address);
                      if (token) {
                        setSelectedToken(token.symbol);
                      } else {
                        setSelectedToken("Custom");
                      }
                    }}
                  />

                  <br />
                  <br />
                  <LoadingButton
                    loading={loadingERC20}
                    variant="outlined"
                    onClick={getERC20Token}
                    color="primary"
                    className="faucet-button"
                  >
                    Get ERC20
                  </LoadingButton>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              margin="10px 0px 0px 0px"
            >
              <LoadingButton
                variant="outlined"
                // color="secondary"
                className="metamask"
                onClick={(e) => {
                  connectMetaMask(nodeHash);
                }}
              // style={{ position: "fixed", right: "20px", bottom: "20px", color: "black" }}
              >
                {/* Add to Metamask */}
                <Metamask
                  width="30px"
                  height="30px"
                  style={{ marginRight: "10px" }}
                />
                {
                  !metamaskLock ? "Unlock and Add to Metamask" : "Add to Metamask"
                }
              </LoadingButton>
            </Grid>
          </Grid>

          {/* <div className="float-child-element faucet-container">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Dropdown Button
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
        
            </div> */}

          {/* <LoadingButton
            variant="outlined"
            // color="secondary"
            className="metamask"
            onClick={(e) => {
              connectMetaMask(nodeHash);
            }}
            style={{ position: "fixed", right: "20px", bottom: "20px", color: "black" }}
          >
            Add to Metamask
          </LoadingButton> */}

        </Stack>
        {/* <LoadingButton
          variant="outlined"
          // color="secondary"
          className="metamask"
          onClick={(e) => {
            connectMetaMask(nodeHash);
          }}
          style={{ position: "absolute", right: "20px", bottom: "20px", color: "black" }}
        >
          Add to Metamask
        </LoadingButton> */}
      </Box>
    </>
  );
};

export default Node;
